
<app-top-header></app-top-header>
<app-toaster></app-toaster>
<div *ngIf="showLoading" class="position-fixed vw-100 vh-100 d-flex justify-content-center align-items-center" style="z-index: 9999; background-color: rgba(255, 255, 255, 0.5);">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Caricamento...</span>
  </div>
</div>


<div #sidebar class="container-fluid pb-5 pt-5 blackBack">
  <div class="row">
    <nav *ngIf="isChild(currentNavigation) || ( currentNavigation && currentNavigation.children?.length && !currentNavigation.isInvisible)"
         [ngClass]="getWidth < 768 ? 'horr' : ''"
         class="col-12 col-md-3 col-xl-2 col-lg-2 d-md-block bg-light sidebar lateralMenu">
      <!-- codice per il menu laterale -->
      <app-lateral-menu></app-lateral-menu>
    </nav>
    <main class="col-12 col-md-9 ml-0 col-lg-10 col-xl-10 mt-md-0 mt-3 pt-md-0 pb-5 mx-auto" [style.padding-top]="getWidth > 10 && getWidth < 768 ? '' : 20 + 'px!important'">
      <!-- codice per il contenuto principale -->
      <router-outlet></router-outlet>
    </main>
  </div>
</div>

<app-footer></app-footer>


