import {Component, OnInit} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {CommonSharedModuleModule} from '../../modules/common-shared-module/common-shared-module.module';
import {FullStore} from '../../../interfaces/store';
import {Agent} from '../../../interfaces/agent_details';
import {Anagraphic} from '../../../interfaces/anagraphic';
import {FormsModule} from '@angular/forms';
import {ButtonType, JsonFormData} from '../../../interfaces/jsonFormData';
import {ApiService} from '../../services/api.service';
import {CommunicationService} from '../../services/communication.service';
import {JsonEditorComponent} from "../../components/json-editor/json-editor.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {ChangePasswordComponent} from "./pages/change-password/change-password.component";
import {AutoModalComponent} from "../../components/auto-modal/auto-modal.component";
import {MatchEditorComponent} from "../../components/match-editor/match-editor.component";
import {
  SubscriptionViewerComponent
} from "../../components/subscriptions/subscription-viewer/subscription-viewer.component";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  standalone: true,
  imports: [CommonSharedModuleModule, FormsModule, JsonEditorComponent, TranslateModule, ChangePasswordComponent, AutoModalComponent, MatchEditorComponent, SubscriptionViewerComponent]
})
export class UserProfileComponent implements OnInit {
  userProfileData: {
    agentDetails?: Agent,
    anagraphic: Anagraphic
  } ;

  formData: JsonFormData = {elements: []};

  changePasswordData: JsonFormData = {buttonType1: ButtonType.button, id: 'passChange', elements: []}
  upgradeData: JsonFormData = {buttonType1: ButtonType.button, id: 'upgrade', elements: []}
  thsUserSubscription: JsonFormData = {buttonType1: ButtonType.button, id: 'myAccount', elements: []}


  constructor(private translate: TranslateService, private communicationService: CommunicationService, private storeService: StoreService, private apiService: ApiService) {
    this.userProfileData = this.storeService.getData().value!;

      this.storeService.getData().subscribe(async (res: FullStore) => {

      if (res.authUser.authUserData.email) {

        this.formData = { elements: []};

        this.userProfileData = {
          anagraphic: res.authUser.anagraphic!,
        };
        this.upgradeData.title = await lastValueFrom(this.translate.get('MAKEUPGRADE')).then((r) => r);
        const myProfileTitle = await lastValueFrom(this.translate.get('USERPROFILE')).then((r) => r);
        this.formData.title = myProfileTitle;
        this.formData.align = 'INLINE';
        this.formData.id = 'MyId';
        this.formData.buttonType1 = ButtonType.button;
        this.formData.elements = [
          {
            'labelWidthClass': '',
            'text': await lastValueFrom(this.translate.get('USEREMAIL')).then((r) => r),
            'mode': 'required',
            'type': 'input',
            'name': 'email',
            'id': 'email',
            'inline': false,
            'editable': false,
            'value': res.authUser.authUserData.email,
            'recipientClass': 'col-6'
          },
          {
            'labelWidthClass': '',
            'text': await lastValueFrom(this.translate.get('LANGSELECTOR')).then((r) => r),
            'type': 'select',
            'recipientClass': 'col-6',
            'inline': true,
            'isDeletable': false,
            'name': 'langId',
            'id'  : 'langId',
            'value': this.translate.currentLang,
            'options': [
              {
                name: 'IT',
                value: 'it'
              },
              {
                name: 'EN',
                value: 'en'
              },
              {
                name: 'FR',
                value: 'fr'
              },
              {
                name: 'ES',
                value: 'es'
              }
            ]
          },
          {
            'type': 'newline',
            'text': '',
            'name': '',
            'id'  : '',
          },
          {
            'labelWidthClass': '',
            'text': await lastValueFrom(this.translate.get('PAYWALLET')).then((r) => r),
            'type': 'input',
            'name': 'payWallet',
            'id': 'payWallet',
            'inline': false,
            'editable': true,
            'value': res.authUser?.authUserData?.payWallet,
            'recipientClass': 'col-12'
          }
        ];

      }


    })
  }

  async ngOnInit() {

    this.changePasswordData.title = await lastValueFrom(this.translate.get('CHANGEPASSWORD')).then((r) => r);

    this.thsUserSubscription.title = await lastValueFrom(this.translate.get('YOURPLAN')).then((r) => r);

    this.storeService.getUserFullAnagraphic().then((res: any) => {
      this.userProfileData.anagraphic = {...res};

      this.storeService.setMyData('authUser.anagraphic', this.userProfileData.anagraphic, true);

    })



  }

  setData(ev: any) {
    debugger;
    this.translate.use(ev.langId);
    this.storeService.mergeMyData('authUser', ev);
    this.translate.use(ev.langId);
    localStorage.setItem(environment.storageLangId, ev.langId);
    this.apiService.makeApiCall('/users/saveOwnUserData', 'POST', ev).subscribe({next: async (res: any) => {
      // console.log('Saved User Data', res);
      if (res.success) {
        this.communicationService.showSuccessToast('Message OK', await lastValueFrom(this.translate.get(res.msgVar)).then((r) => r));
      } else {
        this.communicationService.showErrorToast('Message KO', await lastValueFrom(this.translate.get(res.msgVar)).then((r) => r));
      }

      }, error: () => {}});

    this.translate.use(ev.langId);
  }
  setDataResidence(ev: any) {
    debugger;
    this.storeService.setMyData('authUser.anagraphic.residence', ev, true);
  }

  get userSubscription() {
    return this.storeService.getCurrentStore()?.userSubscription;
  }

  closePasswordPopup() {
    const myModal = document.getElementById('closeModalpassChange');
    myModal!.click();
  }

  protected readonly ButtonType = ButtonType;
}
