
<div class="container">
  <div class="row gx-5">
    <app-auto-modal [model]="'AUTO'" [dataJson]="upgradeData" [modalClass]="'large'" [openButtonText]="'UPGRADE' | translate">
      <app-subscription-viewer [method]="'UPGRADE'"></app-subscription-viewer>
    </app-auto-modal>

    <div class="col-12 m-sm-2 m-md-0 mb-3 pt-3 pb-3" style="background: white">
      <h3>{{'YOURPLAN' | translate}}</h3>
      <div class="row" *ngIf="userSubscription">
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'PLANNAME' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.nameVar | translate}}</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'PLANDURATION' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.duration | translate}}</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'PLANSTARTDATE' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.startingDate | date}}</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'PLANENDDATE' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.endingDate | date}}</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'PLANPRICE' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.finalPrice | currency:'EUR'}}</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'SURPLUS' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.surplusPercentFrom}}% to {{userSubscription.surplusPercentTo}}%</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'CASHOUTSTEPSAMOUNT' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.stepToCashOut}}</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'MAXIMUMBET' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.maxBet | currency:'EUR'}}</h6>
        </div>
        <div class="col-6">
          <p class="mb-2 fw-bolder">{{'REFERRALEARNINGPERCENT' | translate}}</p>
          <h6 class="text-muted f-w-400">{{userSubscription.referralEarningPercent}}%</h6>
        </div>
      </div>

    </div>

    <div class="col-6 col-xl-4 m-sm-2 m-md-0 mt-md-2 text-center">
      <app-auto-modal [model]="'JSON'" [dataJson]="formData" (saveForm)="setData($event)" [openButtonText]="'USERPROFILE' | translate"></app-auto-modal>
    </div>

    <div class="col-6 col-xl-4 m-sm-2 m-md-0 mt-md-2 text-center">
      <app-auto-modal [model]="'AUTO'" [dataJson]="changePasswordData" [openButtonText]="'CHANGEPASSWORD' | translate">
        <app-change-password (actionDone)="closePasswordPopup()"></app-change-password>
      </app-auto-modal>
    </div>

  </div>

</div>

