import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadService} from "../../services/upload.service";
import {ApiService} from "../../services/api.service";
import {Contract_doc} from "../../../interfaces/production";
import {CommunicationService} from "../../services/communication.service";
import {environment} from "../../../environments/environment";
import {FormsModule} from "@angular/forms";
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-uploader',
  standalone: true,
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  imports: [CommonModule, FormsModule]
})
export class UploaderComponent implements AfterViewInit {
  @Input('userId') userId: number = 0;
  @Input('directory') directory: string = '';
  @Input('contractId') contractId: string = '';
  @Input('showFiles') showFiles: boolean = true;
  @Input('showDirectory') showDirectory: boolean = false;
  @Input('filesLimit') filesLimit: string = '100';
  @Output() fileUploaded = new EventEmitter<string>();
  @Output() docsCollection = new EventEmitter<Contract_doc[]>();
  @Output() freeDocsCollection = new EventEmitter<string[]>();

  docList: Contract_doc[] = [];
  freeDocList: string[] = [];



  modalInstance: any;

  constructor(private communicationService: CommunicationService, private uploadService: UploadService, private apiService: ApiService) {

  }

  ngAfterViewInit() {
    if (+this.contractId > 0) {
      this.getFileList();
    } else {
      this.getFreeFileList();
    }

  }


  dataFileDeletion?: any;
  confirmAction(actionId: string, data?: Contract_doc | string) {
      debugger;
      if (data) {
        this.dataFileDeletion = data;
      }

      const modalElement = document.getElementById(actionId);
      if (modalElement) {
         this.modalInstance = new bootstrap.Modal(modalElement);
         this.modalInstance.show();
        modalElement.className = 'showme';
      }
  }


  onFileSelected(event: any) {
    const file: File = event.target.files[0];
debugger;
    if (file) {
      this.uploadService.uploadFile(this.userId, this.directory, file, this.contractId).subscribe(
        {
          next: (res: any) => {
          // Emission of the file URL to parent component
          this.fileUploaded.emit(res.file);
          this.ngAfterViewInit();
        },
        error: (err: any) => {
            console.log(err)
        }
      });
    }
  }

  getFreeFileList() {
    this.apiService.makeApiCall('/users/listFreeFiles', 'POST', {path: 'uploads/' + this.userId + '/' + this.directory}).subscribe({
      next: (res: any) => {
        // fix doc names, keep just name and remove url
        if (res.length <= 0) {
          this.freeDocList = [];
          this.freeDocsCollection.emit(this.freeDocList)
        } else {
          res.forEach((single: string) => {
            const segments = single.split('/');
            single = segments[segments.length - 1];
          })

          this.freeDocList = [];
          res.forEach((sd: Contract_doc) => {
            if (sd.status === 'OPEN') {
              this.freeDocList.push(sd.docUrl)
            }

          });

          this.freeDocsCollection.emit(this.freeDocList)
        }

      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getFileList() {
    this.apiService.makeApiCall('/users/listDocs', 'POST', {userId: this.userId, contractId: this.contractId}).subscribe({
      next: (res: any) => {
        // fix doc names, keep just name and remove url
        if (!res.docList || res.docList?.length <= 0)  {
          this.docList = [];
          this.docsCollection.emit(this.docList)
        } else {
          res.docList.forEach((single: Contract_doc) => {
            const segments = single.docUrl.split('/');
            single.docUrl = segments[segments.length - 1];
          })

          this.docList = [];
          this.docList = res.docList;

          this.freeDocList = [];
debugger
          res.docList.forEach((sd: Contract_doc) => {
            if (sd.status === 'OPEN') {
              this.freeDocList.push(sd.docUrl)
            }

          });

          this.docsCollection.emit(this.docList)
        }
        if (res.msg) {
          alert(res.msg);
        }

      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  deleteFile() {

    let url = '';
    let data = {};

    if (this.dataFileDeletion && typeof this.dataFileDeletion !== 'string') {
      url = '/users/cancelFile';
      data = {
        userId: this.userId,
        docId: this.dataFileDeletion.id
      }
    } else {
      url = '/users/cancelFileByFileName';
      data = {
        userId: this.userId,
        docFileName: this.dataFileDeletion
      }
    }

    this.apiService.makeApiCall(url, 'POST', data).subscribe({
        next: (res: any) => {
            this.getFileList();
            this.closeMe();
        },
        error: (err: any) => {
            console.log(err);
          this.closeMe();
        }
    });
  }

  downloadFile(doc: Contract_doc) {
    let data: any = {
      userId: this.userId,
       docId: doc.id
    }
    if (this.contractId && this.contractId !== '') {
        data.contractId = this.contractId;
    }
    /*
      this.apiService.makeApiCall(`/users/download?userId=${this.userId}&docId=${doc.id}&contractId=${this.contractId}`, 'GET', data).subscribe({
          next: (res: any) => {
              // fix doc names, keep just name and remove url
              res.docList.forEach((single: Contract_doc) => {
                  const segments = single.docUrl.split('/');
                  single.docUrl = segments[segments.length - 1];
              })
              this.docList = res.docList
          },
          error: (err: any) => {
              console.log(err)
          }
      })

     */
  }

  closeMe() {
    const modalElement: any = document.getElementById('deleteFile');
    if (modalElement) {
      modalElement.className = 'hideme';
      this.modalInstance.hide();
    }

  }

  saveFileNotes(doc: Contract_doc) {
      this.apiService.makeApiCall('/admin/saveDocNote', 'POST', {doc}).subscribe((r: any) => {
        console.log('result', r)
        if (r.success) {
          this.communicationService.showSuccessToast('Aggiornamento note', 'Dati correttamente salvati.');
        } else {
          this.communicationService.showErrorToast('Aggiornamento note', 'Errore nel salvataggio, riprova.');
        }
      });
  }

  protected readonly environment = environment;
}
