
<!-- pricing table  -->
<section id="pricing-tables" class="container-fluid mt-4">
  <div class="row gx-0 gx-sm-5" *ngIf="subscriptionPlans && subscriptionPlans!.length > 0 else spinner">
    <ng-container  *ngFor="let sub of subscriptionPlans">
      <app-single-subscription-present *ngIf="method === '' || method === 'UPGRADE' && sub.finalPrice > 0"
         [gotDiscount]="gotDiscount" [subscriptionPlan]="sub" (action)="openModal($event)" (renew)="renewPlan($event)"></app-single-subscription-present>
    </ng-container>
  </div>
  <ng-template #spinner>
    <div class="row">
      <div class="pt-5 text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </ng-template>
</section>

<div class="modal fade" id="newRole" #modal tabindex="-1" aria-labelledby="newRoleLabel" aria-hidden="true">
  <div class="modal-dialog small-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newRoleLabel">{{popupMode | translate}}</h5>
        <button type="button" id="closeNewRoleModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row gx-0">
          <div class="col-12">
            <app-payment-popup *ngIf="subscriptionPlan" [view]="popupMode" [discount]="gotDiscount" [subscriptionPlan]="subscriptionPlan" (actionOut)="closeModal()"></app-payment-popup>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-alert  idModal="confirmPayment" modalText="{{'CONFIRMPURCHASETEXT' | translate}}" title="{{'CONFIRMPURCHASETITLE' | translate}}"></app-confirm-alert>

