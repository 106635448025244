import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ApiService} from '../services/api.service';

export function usernameNotTakenValidator(apiService: ApiService): (control: AbstractControl) => Observable<ValidationErrors | null> {

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return apiService.checkUsernameNotTaken(control.value).pipe(
      map(success => {
        return success ? { usernameTaken: true } : null;
      })
    );
  };
}
