import {Component, EventEmitter, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonSharedModuleModule} from '../../../../modules/common-shared-module/common-shared-module.module';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {StoreService} from '../../../../services/store.service';
import {JsonEditorComponent} from "../../../../components/json-editor/json-editor.component";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [FormsModule, CommonSharedModuleModule, JsonEditorComponent],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {

  @Output() actionDone = new EventEmitter<boolean>();

  formData: any = {};

  constructor(private apiService: ApiService, private router: Router, private storeService: StoreService) {
    this.formData.title = 'Modifica la tua password';
    this.formData.align = 'INLINE';
    this.formData.elements = [
      {
      'labelWidthClass': 'label-width-200',
      'inline': true,
      'text': 'Vecchia password',
      'type': 'password',
      'name': 'oldPass',
      'id'  : 'oldPass'
    },
      {
        'type': 'newline',
        'text': '',
        'name': '',
        'id'  : '',
      },
      {
        'type': 'title',
        'text': 'Nuova password',
        'name': '',
        'id'  : '',
      },
      {
        'labelWidthClass': 'label-width-200',
        'inline': false,
        'text': 'Nuova password',
        'type': 'password',
        'name': 'newPass',
        'id'  : 'newPass',
        'recipientClass': 'col-6 col-md-3 col-lg-4'
      },
      {
        'labelWidthClass': 'label-width-200',
        'inline': false,
        'text': 'Ripeti password',
        'type': 'password',
        'name': 'repeatPass',
        'id'  : 'repeatPass',
        'recipientClass': 'col-6 col-md-3 col-lg-4'
      }];

  }

  changePassword(formData: any) {
    // check passwords match
    if (formData.newPass !== formData.repeatPass) {
      alert('Le 2 password non combaciano');
      return;
    }
    this.apiService.makeApiCall('/users/changePassword', 'POST', {oldPass: formData.oldPass, newPass: formData.newPass}).subscribe({next: async (res: any) => {
        console.log('Response', res);
        this.apiService.makeApiCall('/users/logout').subscribe({next: (res: any) => {}, error: () => {}});
        alert(res.msg);
        debugger;
        if (res.success) {
          this.actionDone.emit(true);
          await this.storeService.doLogout();
          await this.router.navigateByUrl('/home');
        }

      }, error: (err) => {
        console.log('Error', err);
        alert(err.message);
      }})
  }
}
