import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ConfirmAlertComponent} from '../../components/confirm-alert/confirm-alert.component';
import {JsonEditorComponent} from '../../components/json-editor/json-editor.component';
import {FormsModule} from '@angular/forms';
import {PanelComponent} from '../../components/panel/panel.component';
import {RouterLink} from '@angular/router';
import {UploaderComponent} from "../../components/uploader/uploader.component";



@NgModule({
  declarations: [ConfirmAlertComponent, PanelComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    UploaderComponent
  ],
  exports: [
    CommonModule, ConfirmAlertComponent, PanelComponent, UploaderComponent
  ]
})
export class CommonSharedModuleModule { }
