import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ButtonType, JsonFormData} from "../../../interfaces/jsonFormData";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {CommonSharedModuleModule} from "../../modules/common-shared-module/common-shared-module.module";
import {JsonEditorComponent} from "../json-editor/json-editor.component";
import {TranslateModule} from "@ngx-translate/core";
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-auto-modal',
  templateUrl: './auto-modal.component.html',
  styleUrls: ['./auto-modal.component.scss'],
  imports: [
    JsonPipe,
    NgForOf,
    NgIf,
    CommonSharedModuleModule,
    JsonEditorComponent,
    TranslateModule
  ],
  standalone: true
})
export class AutoModalComponent implements OnChanges {

  @Input() dataJson?: JsonFormData;
  @Input() openButtonText: string = '';
  @Input() closeModal?: boolean;
  @Input() openTheModal?: any;
  @Input() model: string = 'JSON';
  @Input() modalClass: string = '';
  @Input() hideOpenButton: boolean = false;
  @Output() hasBeenClosed = new EventEmitter<boolean>();
  @Output() openModal = new EventEmitter<boolean>();
  @Output() actionForm = new EventEmitter<any>();
  @Output() innerAction = new EventEmitter<any>();
  @Output() saveForm = new EventEmitter<any>();

  constructor() {

  }

  ngOnChanges() {
    if (this.closeModal) {
      this.close();
    }
    if (this.openTheModal) {
      this.openTheModalNow();
    }

  }

  openModalOut() {
    this.openModal.emit(true);
  }

  actionFormOut(ev: any) {
    this.actionForm.emit(ev);
  }

  innerActionOut(ev: any) {
    this.innerAction.emit(ev);
  }

  saveFormOut(ev: any) {
    this.close();
    this.saveForm.emit(ev);
  }

  close() {
    this.hasBeenClosed.emit(true)
    const closeModalButton = document.getElementById('closeModal' + this.dataJson!.id!);
    if (closeModalButton) {
      closeModalButton.click();
      this.closeModal = false;
    }
  }

  openTheModalNow() {
    this.openModal.emit(true)

    const openModalButton = new bootstrap.Modal(document.getElementById(this.dataJson!.id!)!)
    if (openModalButton) {
      openModalButton.show();
      this.openTheModal = false;
    }
  }

  protected readonly ButtonType = ButtonType;
}
