import {Component} from '@angular/core';
import {RegistraUserComponent} from "../../components/registra-user/registra-user.component";
import {NgClass, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";


@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    RegistraUserComponent,
    NgIf,
    NgClass,
    TranslateModule
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {

  view = 'REGISTRATION';
  gotoUrl(ev: any) {
    this.view = 'CONFIRMATION'
  }
}
