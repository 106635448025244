import {Component, inject} from '@angular/core';
import {
  SubscriptionViewerComponent
} from "../../components/subscriptions/subscription-viewer/subscription-viewer.component";
import {MatchShowerComponent} from "../../components/match-shower/match-shower.component";
import {StoreService} from "../../services/store.service";
import {NgIf} from "@angular/common";
import {CommonSharedModuleModule} from "../../modules/common-shared-module/common-shared-module.module";
import {TranslateModule} from "@ngx-translate/core";

import {CommunicationService} from "../../services/communication.service";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    SubscriptionViewerComponent,
    MatchShowerComponent,
    NgIf,
    CommonSharedModuleModule,
    TranslateModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  storeService = inject(StoreService);
  communicationService = inject(CommunicationService);

  modalInstance: any;
  toggle() {
    alert('ora?')
    debugger;
  }

  get isLogged() {
    return this.storeService.getCurrentStore().authDone && this.storeService.getCurrentStore().authUser?.isLogged;
  }

}
