<footer class="bg-light fixed-bottom">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <p class="text-center m-0" style="display: flex; justify-content: center; align-items: center; height: 100%;">&copy; 2024 - <a href="https://gptbet.win">GPT Bet</a> for your safe betting!</p>
      </div>
    </div>
  </div>
</footer>

