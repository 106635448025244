
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import {CommunicationService} from '../services/communication.service';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CanDeActivateGuard implements CanDeactivate<any> {

  constructor(private communicationService: CommunicationService) {}
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.communicationService.dataToBeSaved) {
      const confirmNavigation = window.confirm('Hai dei dati non salvati. Sei sicuro di voler lasciare la pagina?');
      if (!confirmNavigation) {
        return false;
      }
    }
    return true;
  }
}

export interface CanComponentDeactivate<T = any, V = any> {



  canDeactivate: (component: T, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState?: RouterStateSnapshot) => Observable<boolean> | Promise<boolean> | boolean;
}
