import {Component, inject} from '@angular/core';
import {StoreService} from "../../services/store.service";
import {ApiService} from "../../services/api.service";
import {UserWallet} from "../../../interfaces/wallet";
import {FullStore} from "../../../interfaces/store";
import {CurrencyPipe, JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {AutoModalComponent} from "../auto-modal/auto-modal.component";
import {ButtonType} from "../../../interfaces/jsonFormData";
import {PaymentPopupComponent} from "../subscriptions/payment-popup/payment-popup.component";

@Component({
  selector: 'app-wallet',
  standalone: true,
  imports: [
    JsonPipe,
    NgForOf,
    NgIf,
    RouterLinkActive,
    NgClass,
    CurrencyPipe,
    TranslateModule,
    AutoModalComponent,
    PaymentPopupComponent,
    RouterLink
  ],
  templateUrl: './wallet.component.html',
  styleUrl: './wallet.component.scss'
})
export class WalletComponent {
  storeService = inject(StoreService);
  apiService = inject(ApiService);

  myWalletData?: UserWallet;

  showCashers: boolean = false;
  openDeposit: any = '';
  openWithdraw: any = '';

  constructor() {
    this.storeService.getData().subscribe((newStore: FullStore) => {
      if (newStore.actionId === 'wallet') {
        this.myWalletData = {...newStore.wallet}
      }
    })
  }

  get userIsLogged() {
    return this.storeService.getData().value.authUser.isLogged;
  }

  deposit() {

  }

  openDepositNow() {
    this.openDeposit = Math.random().toString();
  }

  openWithdrawNow() {
    this.openWithdraw = Math.random().toString();

  }

  protected readonly ButtonType = ButtonType;
}
