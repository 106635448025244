import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {lastValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CachedData, CachedStore} from "../../interfaces/store";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseRouteCall = environment.domain + environment.mainEndpoint;
  cachedData: CachedData = {};
  constructor(private https: HttpClient, private router: Router) { }

  makeApiCall(route: string, method: string = 'POST', data: any = {}) {
    switch (method) {
      case 'GET':
        return this.https.get(this.baseRouteCall + route, data);
      case 'POST':
        return this.https.post(this.baseRouteCall + route, data);
      case 'PUT':
        return this.https.put(this.baseRouteCall + route, data);
      case 'DELETE':
        return this.https.delete(this.baseRouteCall + route);
      default:
        throw new Error(`Invalid method: ${method}`);
    }
  }

  checkEmailNotTaken(email: string): Observable<boolean> {
    debugger
    return this.makeApiCall('/users/emailCheck?emailToCheck=' + email, 'GET', {emailToCheck: email}).pipe(
      map((response: any) => response.success)
    );
  }

  checkUsernameNotTaken(username: string): Observable<boolean> {
    debugger
    return this.makeApiCall('/users/usernameCheck?username=' + username, 'GET', {username: username}).pipe(
      map((response: any) => response.success)
    );
  }

  async authorizedApiCall(route: string, method: string = 'POST', data: any = {}): Promise<any> {
    // check If I got the token
    const myToken =  localStorage.getItem(environment.accessWebToken);
    if (!myToken) {
      return new Promise((res, rej) => {
        res({success: false, results: []});
      })
    }
    return await lastValueFrom(this.makeApiCall(route, method, data)).then((res: any) => {

        if (res || res.success) {
          return res;
        } else {
          this.router.navigateByUrl('/');
        }
      },
      async (err: any) => {

        if (err.status === 401) {

          this.router.navigateByUrl('/logout');
          return {success: false, msg: 401};
        }
        return {success: false, msg: err};

      }).catch((rr: any) => {

      console.log('Catched', rr)
    });
  }


  async freeCachedApiCall(route: string, method: string = 'POST', data: any = {}, expiration: number = 5000) {
    debugger;

    const cached = this.getCachedData({url: route, params: data});
    if (cached && cached.data) {
      return new Promise((res, rej) => {
        res(cached.data);
      })
    }
    // check if I have the data already stored
    return await lastValueFrom(this.makeApiCall(route, method, data)).then((res: any) => {

        if (res || res.success) {
          const cachedDataId: string = route + '|' + this.getUnique({params: data});
          const cachedData: CachedData = {
            id: cachedDataId,
            creationTimestamp: new Date().getTime(),
            durationMilli: expiration,
            data: res
          }
          this.setCachedData(cachedData);

          return res;
        } else {
          this.router.navigateByUrl('/');
        }
      },
      async (err: any) => {

        if (err.status === 401) {

          this.router.navigateByUrl('/logout');
          return {success: false, msg: 401};
        }
        return {success: false, msg: err};

      }).catch((rr: any) => {
      alert('catched?');
      console.log('Catched', rr)
    });
  }


  async authorizedCachedApiCall(route: string, method: string = 'POST', data: any = {}, expiration: number = 5000, reset: boolean = false) {
    debugger;

    if (!reset) {
      const cached = this.getCachedData({url: route, params: data});
      if (cached && cached.data) {
        return new Promise((res, rej) => {
          res(cached.data);
        })
      }
    }

    // check If I got the token
    const myToken =  localStorage.getItem(environment.accessWebToken);
    if (!myToken) {
      return new Promise((res, rej) => {
        res({});
      })
    }
    // check if I have the data already stored
    return await lastValueFrom(this.makeApiCall(route, method, data)).then((res: any) => {

        if (res || res.success) {
          const cachedDataId: string = route + '|' + this.getUnique({params: data});
          const cachedData: CachedData = {
            id: cachedDataId,
            creationTimestamp: new Date().getTime(),
            durationMilli: expiration,
            data: res
          }
          this.setCachedData(cachedData);

          return res;
        } else {
          this.router.navigateByUrl('/');
        }
      },
      async (err: any) => {

        if (err.status === 401) {

          this.router.navigateByUrl('/logout');
          return {success: false, msg: 401};
        }
        return {success: false, msg: err};

      }).catch((rr: any) => {
      alert('catched?');
      console.log('Catched', rr)
    });
  }

  setCachedData(data: CachedData ) {
    const id = data['id'] as keyof CachedStore;
    this.cachedData[id] = data;
  }
  getCachedData(data: {url: string, params: string} ) {
    let unique = data.url + '|' + this.getUnique(data);

    const now = new Date().getTime(); // in millis
    let old = this.cachedData[unique];
    let maxTime = 0;
    if (old) {
      maxTime = this.cachedData[unique].creationTimestamp + this.cachedData[unique].durationMilli;
    }

    if (old && maxTime > now) {
      return old;
    }
    return false;

  }

  getUnique(data: {params: any}): string {
    let unique = '';
    for (const key in data.params) {
      if (data.params.hasOwnProperty(key)) {
        const value = data.params[key];

        if (typeof value !== 'object') {
          unique += `${key}${value}`;
        }
      }
    }
    return unique;
  }

}
