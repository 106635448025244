
<div class="json-editor">
  <form>
    <div class="app-card-header p-3 border-bottom-0" *ngIf="false">
      <div class="row align-items-center gx-3">
        <div class="col-auto">
          <div class="app-icon-holder">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
            </svg>
          </div>
        </div>
        <div class="col-auto">
          <h4 class="app-card-title">{{formConfig?.title}}</h4>
        </div>
      </div>
    </div>
    <div class="row">

      <ng-container *ngFor="let element of formConfig?.elements">
        <ng-container *ngIf="element" [ngSwitch]="element?.type">
          <div class="{{element.inline ? 'col-auto' : element.recipientClass ? element.recipientClass :'col-12'}}" *ngIf="formData">
            <div *ngSwitchCase="'gotoPage'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}</strong></label>
              <div>
                <a [routerLink]="asString(element.value)">{{element?.text || element.name}}</a>
              </div>
            </div>
            <div *ngSwitchCase="'toggle'" class="form-check form-switch">
              <input class="form-check-input" [ngClass]="element.elementClass"
                     (click)="innerAction(element)" type="checkbox" role="switch" id="{{element.id}}"
                     [ngModelOptions]="{standalone: true}"
                     [checked]="formData[element.id]" [(ngModel)]="formData[element.id]">
              <label class="form-check-label" for="{{element.id}}">{{element?.text || element.name}}</label>
            </div>


            <div *ngSwitchCase="'innerButton'" class="mb-1">
              <ng-container *ngIf="element?.editable">
                <label *ngIf="element.name && element.name !== ''" for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
                <div>
                  <i *ngIf="element.iconClass" [ngClass]="element.iconClass" (click)="innerAction(element)"></i>
                  <button *ngIf="!element.iconClass" class="btn mr-3" [ngClass]="element.elementClass" (click)="innerAction(element)">{{element.text || 'Salva'}}</button>
                </div>
              </ng-container>

            </div>
            <div *ngSwitchCase="'uploader'" class="mb-1">
              <ng-container *ngIf="element.uploadUserId && element.directory">
                <label *ngIf="element.text && element.text !== ''" for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element.text}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
                <div>
                  <img *ngIf="formData[element.id]" [src]="formData[element.id]" alt="">
                  <app-uploader [userId]="element.uploadUserId" [filesLimit]="element.json?.filesLimit" [showDirectory]="element.json?.showDirectory" [showFiles]="element.json?.showFiles" [directory]="element.directory" [contractId]="element.json?.contractId" (fileUploaded)="uploadDone($event)" (docsCollection)="docsCollection($event)"></app-uploader>
                </div>
              </ng-container>

            </div>


            <div *ngSwitchCase="'selectSearch'" class="mb-1">
              <label *ngIf="element.text && element.text !== ''" for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div class="d-flex justify-content-center align-items-center">
                  <select #searchIn [disabled]="element?.editable === false" class="form-select flex-grow-1" [id]="element?.id" (change)="search(searchIn.value, element)" [name]="element.id" [required]="element.mode === 'required'">
                    <option *ngFor="let option of element.options" [value]="option.value" [selected]="option.value === element.value">{{option.name}}</option>
                  </select>
                <i *ngIf="element.iconClass" [ngClass]="element.iconClass" (click)="innerAction(element, searchIn.value)"></i>
              </div>
            </div>

            <div *ngSwitchCase="'inputSearch'" class="mb-1">
              <label *ngIf="element.text && element.text !== ''" for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div class="d-flex justify-content-center align-items-center">
                <input [disabled]="element?.editable === false" #searchIn
                       (keydown.enter)="search(searchIn.value, element)"
                       class="form-control"
                       [type]="element?.type || 'text'"
                       [name]="element.name"
                       [value]="chosenResult"
                >
                <div *ngIf="searchResults.length > 0" class="searchResults"><span (click)="searchResults = []">X</span>
                  <ul class="resultList">
                    <li *ngFor="let s of searchResults" (click)="check(s, element); (element.innerProp ? formData[element.innerProp!][element.id] = s[element.interAction.resultValue] : formData[element.id] = s[element.interAction.resultValue] ); chosenResult = s[element.interAction.resultName]; searchResults = []">{{s[element.interAction.resultName]}}</li>
                  </ul>
                  <i *ngIf="element.iconClass" [ngClass]="element.iconClass" (click)="innerAction(element, searchIn.value)"></i>
                </div>

              </div>
            </div>
            <div *ngSwitchCase="'input'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''" [innerHTML]="'<strong>' + (element?.text || element.name) + '</strong>'"><span *ngIf="element.mode === 'required'">*</span></label>
              <div>
                <input *ngIf="element.innerProp && element.id && formData[element.innerProp]" [disabled]="element?.editable === false" class="form-control" [type]="element?.type || 'text'" [id]="element?.id" [(ngModel)]="formData[element.innerProp!][element.id]" [name]="element.id" [required]="element.mode === 'required'">
                <input *ngIf="!element.innerProp" [disabled]="element?.editable === false" [ngStyle]="{'color': element.isOnError ? 'red' : 'initial'}" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''" class="form-control" [type]="element?.type || 'text'" [id]="element?.id" [(ngModel)]="formData[element.id]" [name]="element.id" [required]="element.mode === 'required'">
              </div>

            </div>
            <div *ngSwitchCase="'password'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div>
                <input *ngIf="element.innerProp" [disabled]="element?.editable === false" class="form-control" [type]="element?.type || 'text'" [id]="element?.id" [(ngModel)]="formData[element.innerProp!][element.id]" [name]="element.id" [required]="element.mode === 'required'">
                <input *ngIf="!element.innerProp" [disabled]="element?.editable === false" class="form-control" [type]="element?.type || 'text'" [id]="element?.id" [(ngModel)]="formData[element.id]" [name]="element.id" [required]="element.mode === 'required'">
              </div>
            </div>
            <div *ngSwitchCase="'password2'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div>
                <input *ngIf="element.innerProp" [disabled]="element?.editable === false" class="form-control" [type]="element?.type || 'text'" [id]="element?.id" [(ngModel)]="formData[element.innerProp!][element.id]" [name]="element.id" [required]="element.mode === 'required'">
                <input *ngIf="!element.innerProp" [disabled]="element?.editable === false" class="form-control" [type]="'password'" [id]="element?.id" [(ngModel)]="formData[element.id]" [name]="element.id" [required]="element.mode === 'required'">
                <div *ngIf="formData['password'] !== formData['password2']" class="errorMessage">{{readOptionValue(element, 'notMatchText')}}</div>
              </div>
            </div>
            <div *ngSwitchCase="'newline'" class="mb-1 py-1">
            </div>
            <div *ngSwitchCase="'title'" class="mb-1 item border-bottom py-1 titleSection">
              <strong>{{element.text}}</strong>
            </div>
            <div *ngSwitchCase="'checkbox'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div>
                <input *ngIf="element.innerProp" [disabled]="element?.editable === false" type="checkbox" [id]="element?.id" [checked]="formData[element.id]" [(ngModel)]="formData[element.innerProp!][element.id]" [name]="element.id" [required]="element.mode === 'required'">
                <input *ngIf="!element.innerProp" [disabled]="element?.editable === false" type="checkbox" [id]="element?.id" [checked]="formData[element.id]" [(ngModel)]="formData[element.id]" [name]="element.id" [required]="element.mode === 'required'">
              </div>
            </div>

            <div *ngSwitchCase="'radio'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label " [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div>
                <ng-container *ngFor="let option of element.options" >
                  <input class="form-check-input" [(ngModel)]="formData[element.id]" (change)="changed(null, element)" type="radio" [name]="element.name" [id]="option.name" [value]="option.value" [checked]="element.value === option.value">
                  <label class="form-check-label mx-1" [for]="option.name">{{option.name}}</label>
                </ng-container>
              </div>
            </div>


            <div *ngSwitchCase="'date'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div>
                <input *ngIf="element.innerProp" (ngModelChange)="setDate($event, formData[element.innerProp!][element.id])" [disabled]="element?.editable === false" class="form-control" [type]="element?.type" [id]="element?.id" [(ngModel)]="formData[element.innerProp!][element.id]" [name]="element.id" [required]="element.mode === 'required'">
                <input *ngIf="!element.innerProp" (ngModelChange)="setDate($event, formData[element.id])"  [disabled]="element?.editable === false" class="form-control" [type]="element?.type" [id]="element?.id" [(ngModel)]="formData[element.id]" [name]="element.id" [required]="element.mode === 'required'">
              </div>
            </div>
            <div *ngSwitchCase="'select'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div class="d-flex align-items-center">
                <select *ngIf="element.innerProp && element.id && formData[element.innerProp]" [disabled]="element?.editable === false" class="form-select flex-grow-1" [id]="element?.id" (change)="changed(null, element)" [(ngModel)]="formData[element.innerProp][element.id]" [name]="element.id" [required]="element.mode === 'required'">
                  <option *ngFor="let option of element.options" [value]="option.value" [selected]="option.value === element.value">{{option.name}}</option>
                </select>

                <select *ngIf="!element.innerProp" [disabled]="element?.editable === false" class="form-select" [id]="element?.id" (change)="changed(null, element)" [(ngModel)]="formData[element.id]" [name]="element.id" [required]="element.mode === 'required'">
                  <option *ngFor="let option of element.options" [value]="option.value">{{option.name}}</option>
                </select>
                <i *ngIf="element.isDeletable" class="app-ico-secondary fas fa-power-off ms-2 cursor-pointer" (click)="deleteElement(element)"></i>
              </div>
            </div>
            <div *ngSwitchCase="'selectAdding'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div class="d-flex align-items-center">
                <select *ngIf="element.innerProp && element.id && formData[element.innerProp] && element.options" #mySelect1 (click)="mySelect1.value = 'undefined'" [disabled]="element?.editable === false" class="form-select flex-grow-1" [id]="element?.id" (change)="changed($event, element)"  [name]="element.id" [required]="element.mode === 'required'">
                  <option *ngFor="let option of element.options" [value]="option.value" [selected]="option.value === element.value">{{option.name}}</option>
                </select>

                <select *ngIf="!element.innerProp && element.options" [disabled]="element?.editable === false" #mySelect2 (click)="mySelect2.value = 'undefined'" class="form-select" [id]="element?.id" (change)="changed($event, element)" [name]="element.id" [required]="element.mode === 'required'">
                  <option *ngFor="let option of element.options" [value]="option.value">{{option.name}}</option>
                </select>

                <div class="innerData" *ngIf="element.innerData">
                  <ul>
                    <li *ngFor="let data of element.innerData">
                      {{readInnerData(data, element)}}<i *ngIf="element.isDeletable" class="app-ico-secondary fas fa-power-off ms-2 cursor-pointer" (click)="removeInnerData(data, element.innerData)"></i>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <div *ngSwitchCase="'text'" class="mb-1">
              <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div>
                {{formData[element.id]}}
              </div>
            </div>
            <div *ngSwitchCase="'textarea'" class="mb-1" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''">
              <label for="{{element.id}}" class="item-label col-form-label" ><strong>{{element?.text || element.name}}<span *ngIf="element.mode === 'required'">*</span></strong></label>
              <div>
                <textarea *ngIf="element.innerProp" [disabled]="element.editable === false" class="form-control" [id]="element.id" [(ngModel)]="formData[element.innerProp!][element.id]" [name]="element.id" [required]="element.mode === 'required'"></textarea>
                <textarea *ngIf="!element.innerProp" [disabled]="element.editable === false" class="form-control" [id]="element.id" [(ngModel)]="formData[element.id]" [name]="element.id" [required]="element.mode === 'required'"></textarea>
              </div>
            </div>
            <div *ngSwitchCase="'provincia'" class="mb-1">
              <div class="together">
                <ng-container *ngIf="element?.innerProp && element.innerProp !== ''">
                  <div>
                    <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Nazione<span *ngIf="element.mode === 'required'">*</span></strong></label>
                    <select *ngIf="formData[element.innerProp!]" [disabled]="element?.editable === false" class="form-select" [(ngModel)]="formData[element.innerProp!]['nazione']" #nazione (change)="provinciaSelezionata = nazione.value; filtraComuni()" id="nazione" name="nazione" [required]="element.mode === 'required'">
                      <option *ngFor="let option of nazioni" [value]="option.nome" [selected]="option.nome === element.value">{{option.nome}}</option>
                    </select>
                    <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!element?.innerProp || element.innerProp === ''">
                  <div>
                    <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Nazione<span *ngIf="element.mode === 'required'">*</span></strong></label>
                    <select [disabled]="element?.editable === false" class="form-select" [(ngModel)]="formData['nazione']" #nazione (change)="provinciaSelezionata = nazione.value; filtraComuni()" id="nazione" name="nazione" [required]="element.mode === 'required'">
                      <option *ngFor="let option of nazioni" [value]="option.nome" [selected]="option.nome === element.value">{{option.nome}}</option>
                    </select>
                    <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                  </div>

                </ng-container>

                <ng-container *ngIf="element?.innerProp && element.innerProp !== ''">
                   <div>
                     <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Regione<span *ngIf="element.mode === 'required'">*</span></strong></label>
                     <select *ngIf="formData[element.innerProp!]" [disabled]="element?.editable === false" class="form-select" [id]="element?.id" [(ngModel)]="formData[element.innerProp!]['regione']" (change)="regione && regione?.value ? regioneSelezionata = regione?.value : regioneSelezionata = ''; filtraProvince()" #regione  [name]="element.id" [required]="element.mode === 'required'">
                       <option *ngFor="let option of regioni" [value]="option.nome" [selected]="option.nome === element.value">{{option.nome}}</option>
                    </select>
                     <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!element?.innerProp || element.innerProp === ''">
                  <div>
                    <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Regione<span *ngIf="element.mode === 'required'">*</span></strong></label>
                    <select [disabled]="element?.editable === false" class="form-select" [id]="element?.id" [(ngModel)]="formData['regione']" (change)="regione && regione?.value ? regioneSelezionata = regione?.value : regioneSelezionata = ''; filtraProvince()" #regione  [name]="element.id" [required]="element.mode === 'required'">
                      <option *ngFor="let option of regioni" [value]="option.nome" [selected]="option.nome === element.value">{{option.nome}}</option>
                    </select>
                    <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                  </div>
                </ng-container>

                <ng-container *ngIf="element?.innerProp && element.innerProp !== ''">
                  <div>
                    <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Provincia<span *ngIf="element.mode === 'required'">*</span></strong></label>
                    <select *ngIf="formData[element.innerProp!]" [disabled]="element?.editable === false" class="form-select" [(ngModel)]="formData[element.innerProp!]['provincia']" #provincia (change)="provinciaSelezionata = provincia.value; filtraComuni()" id="provincia" name="provincia" [required]="element.mode === 'required'">
                      <option *ngFor="let option of provinceFiltrate" [value]="option.nome" [selected]="option.nome === element.value">{{option.nome}}</option>
                    </select>
                    <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!element?.innerProp || element.innerProp === ''">
                  <div>
                    <label for="{{element.id}}" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Provincia<span *ngIf="element.mode === 'required'">*</span></strong></label>
                    <select [disabled]="element?.editable === false" class="form-select" [(ngModel)]="formData['provincia']" #provincia (change)="provinciaSelezionata = provincia.value; filtraComuni()" id="provincia" name="provincia" [required]="element.mode === 'required'">
                      <option *ngFor="let option of provinceFiltrate" [value]="option.nome" [selected]="option.nome === element.value">{{option.nome}}</option>
                    </select>
                  </div>
                  <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                </ng-container>

                <ng-container *ngIf="element?.innerProp && element.innerProp !== ''">
                  <div>
                    <label for="comune" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Comune<span *ngIf="element.mode === 'required'">*</span></strong></label>
                    <select *ngIf="formData[element.innerProp!]" [disabled]="element?.editable === false" class="form-select" [(ngModel)]="formData[element.innerProp!]['comune']" id="Comune" name="comune" [required]="element.mode === 'required'">
                      <option *ngFor="let option of comuniFiltrati" [value]="option.cityName" [selected]="option.cityName === element.value3">{{option.cityName}}</option>
                    </select>
                    <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!element?.innerProp || element.innerProp === ''">
                  <div>
                    <label for="comune" class="item-label col-form-label" [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''"><strong>Comune<span *ngIf="element.mode === 'required'">*</span></strong></label>
                    <select [disabled]="element?.editable === false" class="form-select" [(ngModel)]="formData['comune']" id="Comune" name="comune" [required]="element.mode === 'required'">
                      <option *ngFor="let option of comuniFiltrati" [value]="option.cityName" [selected]="option.cityName === element.value">{{option.cityName}}</option>
                    </select>
                    <i *ngIf="element.isDeletable" class="fa-solid fa-power-off" (click)="deleteElement(element)"></i>
                  </div>
                </ng-container>
              </div>

            </div>

            <div *ngSwitchCase="'description'" class="mb-1">
              <div [ngClass]="element.labelWidthClass ? element.labelWidthClass : ''">{{element.value}}</div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>

  <div *ngIf="formConfig?.hideMainAction !== true" class="actionsPanel d-flex align-items-center p-3 mt-3 rounded">
    <button class="btn app-btn-primary mx-3" [disabled]="!requiredFilled()" (click)="onSave()">{{formConfig?.actionText || 'Salva'}}</button>
    <button *ngIf="formConfig?.actionText2" class="btn app-btn-primary mx-3" [ngClass]="formConfig?.text2Class" (click)="actionOnForm()">{{formConfig?.actionText2}}</button>
  </div>
</div>

