import {Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {FullStore} from '../../interfaces/store';
import {NavigationItem} from '../../interfaces/navigation';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ExtendedRolePermission} from '../../interfaces/rolesManagement';
import {Anagraphic} from '../../interfaces/anagraphic';
import {JSEncrypt} from 'jsencrypt';
import {UserWallet} from "../../interfaces/wallet";

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  baseRouteCall = environment.domain + environment.mainEndpoint ;
  private data = new BehaviorSubject<FullStore>({
    authDone: false,
    wallet: {
      GPTB: 0,
      cash: 0,
      cashLevel1: 0,
      cashLevel2: 0,
      cashLevel3: 0,
      cashLevel4: 0,
      cashLevel5: 0,
      cashLevel6: 0,
      cashLevel7: 0,
      cashLevel8: 0,
      cashLevel9: 0
    },
    availableUserMatches: [],
    authUser: {
      langId: 'en',
      userId: 0,
      isLogged: false,
      authUserData: {
        username: '',
        isValidate: false,
        email: ''
      },
      permissions: [],
      anagraphic: {
        hasBackoffice: false,
        addedEmail: '',
        name: '',
        docName: '',
        docReleasedFrom: '',
        surname: '',
        receiveReports: false,
        userId: 0
      }
    },
    navigation: {
      currentUrl: 'home',
      currentNavigation: {
        width: 0,
        path: '/home',
        title: 'La home page del gestionale',
        lateralClassName: 'homeColor',
      }
    }
  });

  constructor(private https: HttpClient) {
  }


  getTotalWalletAmount() {
    let total = 0;
    for (const idxKey in this.userWallet) {
      if (idxKey !== 'GPTB') {
        total += +this.userWallet[idxKey as keyof UserWallet];
      }
    }
    return total;
  }

  getUsableWalletAmount() {
    return +this.userWallet.cash + +this.userWallet.cashLevel1 + +this.userWallet.cashLevel2 + +this.userWallet.cashLevel3
  }

  get userWallet() {
    return this.data.value.wallet
  }
  /*
  * encription Layer
  * */

  public encryptData(data: string, publicKey: string): string | boolean {
    debugger
    let jsEncrypt = new JSEncrypt({});
    jsEncrypt.setPublicKey(publicKey);
    const encryptedKey = jsEncrypt.encrypt(data);
    this.setMyData('encryptedKey', encryptedKey)
    return encryptedKey;
  }

  /*
* encription Layer
* */

  setMyData(key: string, value: any, create: boolean = false) {

    const newData: any = JSON.parse(JSON.stringify(this.data.value));
    debugger;
    let obj = newData;
    // if inner key.key2
    const innerKeys = key.split('.');

    if (innerKeys.length === 2) {
      // I have inner
      if (!obj[innerKeys[0]] && create) {
        if (Array.isArray(value) && !innerKeys[1]) {
          obj[innerKeys[0]] = [];
        } else {
          obj[innerKeys[0]] = {};
        }

      }

      if (!obj[innerKeys[0]][innerKeys[1]] && create) {
        if (Array.isArray(value) && !innerKeys[2]) {
          obj[innerKeys[0]][innerKeys[1]] = [];
        } else {
          obj[innerKeys[0]][innerKeys[1]] = {};
        }

      }
      if (!obj[innerKeys[0]][innerKeys[1]]) {
        return;
      }

      if (typeof value === 'string') {
        obj[innerKeys[0]][innerKeys[1]] = value;
      } else if (Array.isArray(value)) {
        obj[innerKeys[0]][innerKeys[1]] = [...value];
      } else {
        obj[innerKeys[0]][innerKeys[1]] = {...value};
      }

    } else if (innerKeys.length === 3) {
      // I have inner
      if (!obj[innerKeys[0]][innerKeys[1]] && create) {
        obj[innerKeys[0]][innerKeys[1]] = {}
      }
      if (!obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] && create) {
        if (Array.isArray(value)) {
          obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = [];
        } else {
          obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = {};
        }

      }
      if (!obj[innerKeys[0]][innerKeys[1]][innerKeys[2]]) {
        return;
      }

      if (typeof value === 'string') {
        obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = value;
      } else if (Array.isArray(value)) {
        obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = [...value];
      } else {
        obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = {...value};
      }

    } else {
      // i dont have inners
      if (!obj[key] && create) {
        obj[key] = {};
      }
      if (typeof obj[key] === 'undefined' ) {
        return;
      }
      if (typeof value === 'string') {
        obj[key] = value;
      } else if (Array.isArray(value)) {
        obj[key] = [...value];
      } else {
        obj[key] = {...value};
      }

    }

    newData.actionId = key;
    this.setData(newData);
  }

  mergeMyData(key: string, value: any, create: boolean = false) {

    const newData: any = JSON.parse(JSON.stringify(this.data.value));
    debugger;
    let obj = newData;
    // if inner key.key2
    const innerKeys = key.split('.');

    if (innerKeys.length === 2) {
      // I have inner
      if (!obj[innerKeys[0]] && create) {
        if (Array.isArray(value)) {
          obj[innerKeys[0]] = [];
        } else {
          obj[innerKeys[0]] = {};
        }

      }

      if (!obj[innerKeys[0]][innerKeys[1]] && create) {
        if (Array.isArray(value)) {
          obj[innerKeys[0]][innerKeys[1]] = [];
        } else {
          obj[innerKeys[0]][innerKeys[1]] = {};
        }

      }
      if (!obj[innerKeys[0]][innerKeys[1]]) {
        return;
      }

      if (typeof value === 'string') {
        obj[innerKeys[0]][innerKeys[1]] = value;
      } else if (Array.isArray(value)) {
        obj[innerKeys[0]][innerKeys[1]] = [...obj[innerKeys[0]][innerKeys[1]], ...value];
      } else {
        obj[innerKeys[0]][innerKeys[1]] = {...obj[innerKeys[0]][innerKeys[1]], ...value};
      }

    } else if (innerKeys.length === 3) {
      // I have inner
      if (!obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] && create) {
        if (Array.isArray(value)) {
          obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = [];
        } else {
          obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = {};
        }

      }
      if (!obj[innerKeys[0]][innerKeys[1]][innerKeys[2]]) {
        return;
      }

      if (typeof value === 'string') {
        obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = value;
      } else if (Array.isArray(value)) {
        obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = [...obj[innerKeys[0]][innerKeys[1]][innerKeys[2]], ...value];
      } else {
        obj[innerKeys[0]][innerKeys[1]][innerKeys[2]] = {...obj[innerKeys[0]][innerKeys[1]][innerKeys[2]], ...value};
      }

    } else {
      // i dont have inners
      if (!obj[key] && create) {
        obj[key] = {};
      }
      if (typeof obj[key] === 'undefined' ) {
        return;
      }
      if (typeof value === 'string') {
        obj[key] = value;
      } else if (Array.isArray(value)) {
        obj[key] = [...obj[key], ...value];
      } else {
        obj[key] = {...obj[key], ...value};
      }

    }

    newData.actionId = key;
    this.mergeData(newData);
  }

  mergeData(newData: any): void {
    this.data.next({...newData, ...this.data.value});
  }

  setData(newData: any): void {
    this.data.next({...newData});
  }

  getData(): BehaviorSubject<any> {
    return this.data;
  }

  getCurrentStore() {
    return this.data.value;
  }

  getCurrentRoute(routes: NavigationItem[], targetPath: string, parentPath = ''): NavigationItem | null {
    for (const route of routes) {
      const fullPath = `${parentPath}/${route.path}`.replace(/^\//, '');
      if (fullPath === targetPath?.replace(/^\//, '')) {
        return route;
      }
      if (route.children) {
        const found: NavigationItem | null = this.getCurrentRoute(route.children, targetPath, fullPath);
        if (found) {
          return found;
        }
      }
    }

    return null;
  }

  makeApiCall(route: string, method: string = 'POST', data: any = {}) {
    switch (method) {
      case 'GET':
        return this.https.get(this.baseRouteCall + route, data);
      case 'POST':
        return this.https.post(this.baseRouteCall + route, data);
      case 'PUT':
        return this.https.put(this.baseRouteCall + route, data);
      case 'DELETE':
        return this.https.delete(this.baseRouteCall + route);
      default:
        throw new Error(`Invalid method: ${method}`);
    }
  }

  getAuthUser() {
    const get = this.makeApiCall('/users/authUser', 'POST', {}).subscribe((resp: any) => {
      delete resp.success;
      if (resp?.userId) {
        debugger
        resp.isLogged = true;
        this.setMyData('userSubscription', {...resp.userSubscription}, true);
        delete resp.userSubscription;
        this.setMyData('authUser', resp);
      }
      // auth done
      this.setMyData('authDone', true);

    },
      (err) => {
        this.setMyData('authDone', true);
      });


  }

  async getUserFullAnagraphic(userId?: number): Promise<Anagraphic> {
    return await this.makeApiCall('/users/getOwnAnagraphicData', 'POST', {}).toPromise().then((res: any) => {
      console.log('data ricevuta', res.data);
      return res.data;
    });
  }

  async doLogin(email: string, password: string, rememberMe: boolean) {
    return lastValueFrom(this.makeApiCall('/users/login', 'POST', {
      email,
      password,
      rememberMe
    })).then((resp: any) => {
      delete resp.success;
      // console.log('Resp login', resp);
      debugger;
      if (resp?.userId) {
        resp.isLogged = true;
        localStorage.setItem(environment.accessWebToken, resp.authUserData.token);
        this.setMyData('authUser', resp);
        this.setMyData('userSubscription', {...resp.userSubscription}, true);
        return {success: true};
      }


      return {success: false, msgVar: resp.msgVar};
    });
  }

  async doLogout() {
    this.makeApiCall('/users/logout', 'POST', {
      userId: this.data.value.authUser?.userId
    }).subscribe((response) => {
     // console.log('response logout', response);
    });
    const currentValue: FullStore = this.data.value;
    currentValue.authUser = this.resettedUser;
    this.setMyData('authUser', currentValue.authUser);
    localStorage.removeItem(environment.accessWebToken);
  }


  get resettedUser() {
    return {
      langId: 'en',
      userId: 0,
      isLogged: false,
      authUserData: {
        username: '',
        isValidate: false,
        email: ''
      },
      permissions: []
    }
  }

  setOptions(data: any[], propToGet: string = 'nome'): any[] {
    debugger
    if (typeof data === 'undefined') {return []; }
    const options: any[] = [{value: 'undefined', name: 'Scegli'}];
    data.forEach((single: any) => {
      options.push({
        name: single[propToGet],
        value: single.id
      });
    });
    return options;
  }



  verifyRequestedPermission(permissionRequested: string, permission: string[]): boolean {

    if (!permissionRequested) {
      alert('is empty');
      return true;
    }
    let foundPermission: boolean = false;

    this.data.value.authUser?.permissions.forEach((singlePermission: ExtendedRolePermission) => {
      if (singlePermission.name === permissionRequested) {
        permission.forEach((request: string) => {
          if (permission.includes('canRead') && singlePermission.canRead) {
            foundPermission = true;
          }
          if (permission.includes('canWrite') && singlePermission.canWrite) {
            foundPermission = true;
          }
          if (permission.includes('canCreate') && singlePermission.canCreate) {
            foundPermission = true;
          }
          if (permission.includes('canDelete') && singlePermission.canDelete) {
            foundPermission = true;
          }
        });
      }
    });
    if (!permissionRequested || permission?.length <= 0) {
      foundPermission = true;
    }
    return foundPermission;
  }

  // Funzione per convertire una data MySQL in una data HTML
  convertMysqlToHtmlDate(mysqlDateTime: any): string {
    let date
    if (!mysqlDateTime && mysqlDateTime !== null) {
      date = new Date();
    } else if (mysqlDateTime === null) {
      return '';
    } else {
      date = new Date(mysqlDateTime);
    }
    // console.log('mydateb: ', mysqlDateTime);
    let year = date.getUTCFullYear();
    let month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // I mesi in JavaScript partono da 0
    let day = ('0' + date.getUTCDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

// Funzione per convertire una data HTML in una data MySQL
  convertHtmlToMysqlDate(htmlDate: string): string {
    let date = new Date(htmlDate);
    // Aggiunge l'ora 00:00:00.000Z per ottenere un formato completo MySQL datetime
    return new Date(date.setUTCHours(0, 0, 0, 0)).toISOString();
  }

  createPagesArray(X: number) {
    return Array.from({ length: X }, (_, i) => i + 1);
  }

}

export const navigationTree: NavigationItem[] = [

  {
    path: 'home',
    iconName: 'fas fa-home',
    title: 'Home',
    lateralClassName: 'homeColor',
    children: [

    ]
  },
  {
    path: 'auth',
    iconName: 'fas fa-tachometer-alt',
    title: 'Dashboard',
    lateralClassName: 'homeColor',
    permissionNeeded: {
      permissionName: 'utenteRegistrato',
      permissions: ['canRead', 'canWrite']
    },
    children: [
      {
        path: 'dashboard',
        iconName: 'fas fa-tachometer-alt',
        title: 'DASHBOARD',
        lateralClassName: 'homeColor',
        permissionNeeded: {
          permissionName: 'utenteRegistrato',
          permissions: ['canRead', 'canWrite']
        }
      },
      {
        path: 'myBets',
        iconName: 'bi bi-cash-coin',
        title: 'MYBETS',
        lateralClassName: 'homeColor',
        permissionNeeded: {
          permissionName: 'utenteRegistrato',
          permissions: ['canRead', 'canWrite']
        }
      },
      {
        path: 'profile',
        iconName: 'fas fa-id-card',
        title: 'PROFILE',
        lateralClassName: 'homeColor',
        permissionNeeded: {
          permissionName: 'utenteRegistrato',
          permissions: ['canRead', 'canWrite']
        }
      },
      {
        path: 'cashier',
        iconName: 'bi bi-cash-coin',
        title: 'CASHIER',
        lateralClassName: 'homeColor',
        permissionNeeded: {
          permissionName: 'utenteRegistrato',
          permissions: ['canRead', 'canWrite']
        }
      }
    ]
  },
  {
    path: 'admin',
    iconName: 'fas fa-cogs',
    title: 'Amministrazione',
    lateralClassName: 'adminColor',
    permissionNeeded: {
      permissionName: 'ALLUSERSACTION',
      permissions: ['canRead', 'canWrite', 'canDelete', 'canCancel']
    },
    children: [
      {
        permissionNeeded: {
          permissionName: 'AMMINISTRATIVOMAINSETUP',
          permissions: ['canRead']
        },
        iconName: 'bi bi-gear-wide-connected',
        path: 'setup',
        title: 'Setup',
      },
      {
        permissionNeeded: {
          permissionName: 'AMMINISTRATIVOMAINSETUP',
          permissions: ['canRead', 'canWrite', 'canDelete', 'canCancel']
        },
        iconName: 'bi bi-calendar-event',
        path: 'matches',
        title: 'Eventi',
      },
      {
        permissionNeeded: {
          permissionName: 'ALLUSERSACTION',
          permissions: ['canRead', 'canWrite', 'canDelete', 'canCancel']
        },
        iconName: 'bi bi-people-fill',
        path: 'users',
        title: 'Utenti',
      }
    ]
  },
  {
    path: 'network',
    iconName: 'fas fa-home',
    title: 'Rete',
    lateralClassName: 'homeColor',
    permissionNeeded: {
      permissionName: 'utenteRegistrato',
      permissions: ['canRead', 'canWrite']
    },
    children: [
      {
        path: 'view',
        iconName: 'fas fa-home',
        title: 'networkViev',
        lateralClassName: 'homeColor',
        permissionNeeded: {
          permissionName: 'utenteRegistrato',
          permissions: ['canRead', 'canWrite']
        },
      }
    ]
  },
  {
    path: 'login',
    iconName: 'fas fa-sign-in-alt',
    title: 'Login',
    lateralClassName: 'netColor',
    mustBeNotLogged: true
  },
  {
    path: 'login',
    iconName: 'fas fa-sign-out-alt',
    title: 'Log Out',
    lateralClassName: 'netColor',
    permissionNeeded: {
      permissionName: 'utenteRegistrato',
      permissions: ['canRead', 'canWrite']
    }
  }
]

export const provinceItalia: any[] = [
  {
    "nome": "Agrigento",
    "sigla": "AG",
    "regione": "Sicilia"
  },
  {
    "nome": "Alessandria",
    "sigla": "AL",
    "regione": "Piemonte"
  },
  {
    "nome": "Ancona",
    "sigla": "AN",
    "regione": "Marche"
  },
  {
    "nome": "Arezzo",
    "sigla": "AR",
    "regione": "Toscana"
  },
  {
    "nome": "Ascoli Piceno",
    "sigla": "AP",
    "regione": "Marche"
  },
  {
    "nome": "Asti",
    "sigla": "AT",
    "regione": "Piemonte"
  },
  {
    "nome": "Avellino",
    "sigla": "AV",
    "regione": "Campania"
  },
  {
    "nome": "Bari",
    "sigla": "BA",
    "regione": "Puglia"
  },
  {
    "nome": "Barletta-Andria-Trani",
    "sigla": "BT",
    "regione": "Puglia"
  },
  {
    "nome": "Belluno",
    "sigla": "BL",
    "regione": "Veneto"
  },
  {
    "nome": "Benevento",
    "sigla": "BN",
    "regione": "Campania"
  },
  {
    "nome": "Bergamo",
    "sigla": "BG",
    "regione": "Lombardia"
  },
  {
    "nome": "Biella",
    "sigla": "BI",
    "regione": "Piemonte"
  },
  {
    "nome": "Bologna",
    "sigla": "BO",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Bolzano/Bozen",
    "sigla": "BZ",
    "regione": "Trentino-Alto Adige/Südtirol"
  },
  {
    "nome": "Brescia",
    "sigla": "BS",
    "regione": "Lombardia"
  },
  {
    "nome": "Brindisi",
    "sigla": "BR",
    "regione": "Puglia"
  },
  {
    "nome": "Cagliari",
    "sigla": "CA",
    "regione": "Sardegna"
  },
  {
    "nome": "Caltanissetta",
    "sigla": "CL",
    "regione": "Sicilia"
  },
  {
    "nome": "Campobasso",
    "sigla": "CB",
    "regione": "Molise"
  },
  {
    "nome": "Carbonia-Iglesias",
    "sigla": "CI",
    "regione": "Sardegna"
  },
  {
    "nome": "Caserta",
    "sigla": "CE",
    "regione": "Campania"
  },
  {
    "nome": "Catania",
    "sigla": "CT",
    "regione": "Sicilia"
  },
  {
    "nome": "Catanzaro",
    "sigla": "CZ",
    "regione": "Calabria"
  },
  {
    "nome": "Chieti",
    "sigla": "CH",
    "regione": "Abruzzo"
  },
  {
    "nome": "Como",
    "sigla": "CO",
    "regione": "Lombardia"
  },
  {
    "nome": "Cosenza",
    "sigla": "CS",
    "regione": "Calabria"
  },
  {
    "nome": "Cremona",
    "sigla": "CR",
    "regione": "Lombardia"
  },
  {
    "nome": "Crotone",
    "sigla": "KR",
    "regione": "Calabria"
  },
  {
    "nome": "Cuneo",
    "sigla": "CN",
    "regione": "Piemonte"
  },
  {
    "nome": "Enna",
    "sigla": "EN",
    "regione": "Sicilia"
  },
  {
    "nome": "Fermo",
    "sigla": "FM",
    "regione": "Marche"
  },
  {
    "nome": "Ferrara",
    "sigla": "FE",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Firenze",
    "sigla": "FI",
    "regione": "Toscana"
  },
  {
    "nome": "Foggia",
    "sigla": "FG",
    "regione": "Puglia"
  },
  {
    "nome": "Forlì-Cesena",
    "sigla": "FC",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Frosinone",
    "sigla": "FR",
    "regione": "Lazio"
  },
  {
    "nome": "Genova",
    "sigla": "GE",
    "regione": "Liguria"
  },
  {
    "nome": "Gorizia",
    "sigla": "GO",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "nome": "Grosseto",
    "sigla": "GR",
    "regione": "Toscana"
  },
  {
    "nome": "Imperia",
    "sigla": "IM",
    "regione": "Liguria"
  },
  {
    "nome": "Isernia",
    "sigla": "IS",
    "regione": "Molise"
  },
  {
    "nome": "L'Aquila",
    "sigla": "AQ",
    "regione": "Abruzzo"
  },
  {
    "nome": "La Spezia",
    "sigla": "SP",
    "regione": "Liguria"
  },
  {
    "nome": "Latina",
    "sigla": "LT",
    "regione": "Lazio"
  },
  {
    "nome": "Lecce",
    "sigla": "LE",
    "regione": "Puglia"
  },
  {
    "nome": "Lecco",
    "sigla": "LC",
    "regione": "Lombardia"
  },
  {
    "nome": "Livorno",
    "sigla": "LI",
    "regione": "Toscana"
  },
  {
    "nome": "Lodi",
    "sigla": "LO",
    "regione": "Lombardia"
  },
  {
    "nome": "Lucca",
    "sigla": "LU",
    "regione": "Toscana"
  },
  {
    "nome": "Macerata",
    "sigla": "MC",
    "regione": "Marche"
  },
  {
    "nome": "Mantova",
    "sigla": "MN",
    "regione": "Lombardia"
  },
  {
    "nome": "Massa-Carrara",
    "sigla": "MS",
    "regione": "Toscana"
  },
  {
    "nome": "Matera",
    "sigla": "MT",
    "regione": "Basilicata"
  },
  {
    "nome": "Medio Campidano",
    "sigla": "VS",
    "regione": "Sardegna"
  },
  {
    "nome": "Messina",
    "sigla": "ME",
    "regione": "Sicilia"
  },
  {
    "nome": "Milano",
    "sigla": "MI",
    "regione": "Lombardia"
  },
  {
    "nome": "Modena",
    "sigla": "MO",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Monza e della Brianza",
    "sigla": "MB",
    "regione": "Lombardia"
  },
  {
    "nome": "Napoli",
    "sigla": "NA",
    "regione": "Campania"
  },
  {
    "nome": "Novara",
    "sigla": "NO",
    "regione": "Piemonte"
  },
  {
    "nome": "Nuoro",
    "sigla": "NU",
    "regione": "Sardegna"
  },
  {
    "nome": "Ogliastra",
    "sigla": "OG",
    "regione": "Sardegna"
  },
  {
    "nome": "Olbia-Tempio",
    "sigla": "OT",
    "regione": "Sardegna"
  },
  {
    "nome": "Oristano",
    "sigla": "OR",
    "regione": "Sardegna"
  },
  {
    "nome": "Padova",
    "sigla": "PD",
    "regione": "Veneto"
  },
  {
    "nome": "Palermo",
    "sigla": "PA",
    "regione": "Sicilia"
  },
  {
    "nome": "Parma",
    "sigla": "PR",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Pavia",
    "sigla": "PV",
    "regione": "Lombardia"
  },
  {
    "nome": "Perugia",
    "sigla": "PG",
    "regione": "Umbria"
  },
  {
    "nome": "Pesaro e Urbino",
    "sigla": "PU",
    "regione": "Marche"
  },
  {
    "nome": "Pescara",
    "sigla": "PE",
    "regione": "Abruzzo"
  },
  {
    "nome": "Piacenza",
    "sigla": "PC",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Pisa",
    "sigla": "PI",
    "regione": "Toscana"
  },
  {
    "nome": "Pistoia",
    "sigla": "PT",
    "regione": "Toscana"
  },
  {
    "nome": "Pordenone",
    "sigla": "PN",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "nome": "Potenza",
    "sigla": "PZ",
    "regione": "Basilicata"
  },
  {
    "nome": "Prato",
    "sigla": "PO",
    "regione": "Toscana"
  },
  {
    "nome": "Ragusa",
    "sigla": "RG",
    "regione": "Sicilia"
  },
  {
    "nome": "Ravenna",
    "sigla": "RA",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Reggio di Calabria",
    "sigla": "RC",
    "regione": "Calabria"
  },
  {
    "nome": "Reggio Emilia",
    "sigla": "RE",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Rieti",
    "sigla": "RI",
    "regione": "Lazio"
  },
  {
    "nome": "Rimini",
    "sigla": "RN",
    "regione": "Emilia-Romagna"
  },
  {
    "nome": "Roma",
    "sigla": "RM",
    "regione": "Lazio"
  },
  {
    "nome": "Rovigo",
    "sigla": "RO",
    "regione": "Veneto"
  },
  {
    "nome": "Salerno",
    "sigla": "SA",
    "regione": "Campania"
  },
  {
    "nome": "Sassari",
    "sigla": "SS",
    "regione": "Sardegna"
  },
  {
    "nome": "Savona",
    "sigla": "SV",
    "regione": "Liguria"
  },
  {
    "nome": "Siena",
    "sigla": "SI",
    "regione": "Toscana"
  },
  {
    "nome": "Siracusa",
    "sigla": "SR",
    "regione": "Sicilia"
  },
  {
    "nome": "Sondrio",
    "sigla": "SO",
    "regione": "Lombardia"
  },
  {
    "nome": "Taranto",
    "sigla": "TA",
    "regione": "Puglia"
  },
  {
    "nome": "Teramo",
    "sigla": "TE",
    "regione": "Abruzzo"
  },
  {
    "nome": "Terni",
    "sigla": "TR",
    "regione": "Umbria"
  },
  {
    "nome": "Torino",
    "sigla": "TO",
    "regione": "Piemonte"
  },
  {
    "nome": "Trapani",
    "sigla": "TP",
    "regione": "Sicilia"
  },
  {
    "nome": "Trento",
    "sigla": "TN",
    "regione": "Trentino-Alto Adige"
  },
  {
    "nome": "Treviso",
    "sigla": "TV",
    "regione": "Veneto"
  },
  {
    "nome": "Trieste",
    "sigla": "TS",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "nome": "Udine",
    "sigla": "UD",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "nome": "Valle d'Aosta",
    "sigla": "AO",
    "regione": "Valle d'Aosta"
  },
  {
    "nome": "Varese",
    "sigla": "VA",
    "regione": "Lombardia"
  },
  {
    "nome": "Venezia",
    "sigla": "VE",
    "regione": "Veneto"
  },
  {
    "nome": "Verbano-Cusio-Ossola",
    "sigla": "VB",
    "regione": "Piemonte"
  },
  {
    "nome": "Vercelli",
    "sigla": "VC",
    "regione": "Piemonte"
  },
  {
    "nome": "Verona",
    "sigla": "VR",
    "regione": "Veneto"
  },
  {
    "nome": "Vibo Valentia",
    "sigla": "VV",
    "regione": "Calabria"
  },
  {
    "nome": "Vicenza",
    "sigla": "VI",
    "regione": "Veneto"
  },
  {
    "nome": "Viterbo",
    "sigla": "VT",
    "regione": "Lazio"
  }
];

export const nazioni: any[] = [
  {
    nome: 'Italia'
  }
];
export const regioniItalia: any[] = [
  {
  "id": "13",
  "nome": "Abruzzo",
  "latitudine": 42.354008,
  "longitudine": 13.391992
  },
  {
  "id": "17",
  "nome": "Basilicata",
  "latitudine": 40.633333,
  "longitudine": 15.800000
  },
  {
  "id": "18",
  "nome": "Calabria",
  "latitudine": 38.910000,
  "longitudine": 16.587500
}, {
  "id": "15",
  "nome": "Campania",
  "latitudine": 40.833333,
  "longitudine": 14.250000
}, {
  "id": "8",
  "nome": "Emilia-Romagna",
  "latitudine": 44.493889,
  "longitudine": 11.342778
}, {
  "id": "6",
  "nome": "Friuli-Venezia Giulia",
  "latitudine": 45.636111,
  "longitudine": 13.804167
}, {
  "id": "12",
  "nome": "Lazio",
  "latitudine": 41.893056,
  "longitudine": 12.482778
}, {
  "id": "7",
  "nome": "Liguria",
  "latitudine": 44.411156,
  "longitudine": 8.932661
}, {
  "id": "3",
  "nome": "Lombardia",
  "latitudine": 45.464161,
  "longitudine": 9.190336
}, {
  "id": "11",
  "nome": "Marche",
  "latitudine": 43.616667,
  "longitudine": 13.516667
}, {
  "id": "14",
  "nome": "Molise",
  "latitudine": 41.561000,
  "longitudine": 14.668400
}, {
  "id": "1",
  "nome": "Piemonte",
  "latitudine": 45.066667,
  "longitudine": 7.700000
}, {
  "id": "16",
  "nome": "Puglia",
  "latitudine": 41.125278,
  "longitudine": 16.866667
}, {
  "id": "20",
  "nome": "Sardegna",
  "latitudine": 39.216667,
  "longitudine": 9.116667
}, {
  "id": "19",
  "nome": "Sicilia",
  "latitudine": 38.115556,
  "longitudine": 13.361389
}, {
  "id": "9",
  "nome": "Toscana",
  "latitudine": 43.771389,
  "longitudine": 11.254167
}, {
  "id": "4",
  "nome": "Trentino-Alto Adige",
  "latitudine": 46.066667,
  "longitudine": 11.116667
}, {
  "id": "10",
  "nome": "Umbria",
  "latitudine": 43.112100,
  "longitudine": 12.388800
}, {
  "id": "2",
  "nome": "Valle d'Aosta",
  "latitudine": 45.737222,
  "longitudine": 7.320556
}, {
  "id": "5",
  "nome": "Veneto",
  "latitudine": 45.439722,
  "longitudine": 12.331944
}]


