<div class="card" style="background: #ececec; border-radius: 5px;">
  <div class="card-header">
    <h4 class="card-title">
      <ng-content select="[title]">
      </ng-content>
    </h4>
  </div>
  <div class="card-body">
    <ng-content></ng-content>
  </div>
</div>

