
<div class="navbar-nav" style="min-height: 100vh;">
  <ul class="nav nav-pills flex-column mb-auto">
    <ng-container *ngFor="let item of children">
      <li class="nav-item" [ngClass]="item.lateralClassName" *ngIf="!item.isInvisible && getPermission(item)">
        <a class="nav-link" [routerLink]="routeUrl + '/' + item.path" routerLinkActive="active" aria-current="page">
          <i *ngIf="item.iconName" [class]="item.iconName" class="ms-md-1 me-md-2"></i>
          <span [ngClass]="{'d-none d-md-inline': item.iconName}" class="ml-2 d-block">{{(item.itemName || item.title) | translate}}</span>
        </a>
      </li>
    </ng-container>

  </ul>
</div>



