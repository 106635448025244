<div class="container">
  <div class="row">
    <div class="col-12">
      <p class="mb-1 text-center"><b>Bookmater calculator</b></p>
    </div>
  </div>
  <div class="row">
    <div class="col-6 pe-1">
      <div class="row">
        <div class="col-6 ps-1 pe-1">
          <span class="label">Puntata</span>
          {{gptBet | currency:' '}}
        </div>
        <div class="col-6 ps-1 pe-1">
          <span class="label">Quota</span>
          {{gptQuota}}
        </div>
      </div>
    </div>
    <div class="col-6 ps-1">
      <div class="row">
        <div class="col-6 ps-1 pe-1">
          <span class="label">Banca</span>
          <input class="w-50-px" [(ngModel)]="banco">
        </div>
        <div class="col-6 ps-1 pe-1">
          <span class="label">Quota</span>
          <input class="w-50-px" [(ngModel)]="extQuota">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      GPTWin: {{vincitaGpt() | currency: ' '}}
    </div>
    <div class="col-6">
      ExtWin: {{vincitaExt() | currency: ' '}}
    </div>
  </div>

  <div class="row" *ngIf="banco > 0 && extQuota">
    <div class="col-6">
      Matched Win: {{matchedGpt() | currency: ' '}}
    </div>
    <div class="col-6">
      Matched Win: {{matchedExt() | currency: ' '}}
    </div>
  </div>

</div>
