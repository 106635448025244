// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  accessWebToken: "gptBet",
  storageLangId: 'gptBetLangId',
  domain: 'https://dev.gptbet.win',
  mainEndpoint: '/fn',
  keyToEncrypt: 'ioSperiamo_che_me-laCav0',
  publicKey: 'KQpI3wxmImxztxkiLUnjM+w0BelysnvVEE3uUgWSYLNF7qdGa+OK5wR6x21FP27/jx6CxHnPndRXfBNK3/fFHUmM2o+45HOTddPbj/Y62J4w4e73CiWcHpm3w9PyEIxvDKix3+CN6FhTdZg6jkygoBeWkx/Xd0Dis29o7ltOBDo='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
