import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ApiService} from '../services/api.service';

export function emailNotTakenValidator(apiService: ApiService): (control: AbstractControl) => Observable<ValidationErrors | null> {

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return apiService.checkEmailNotTaken(control.value).pipe(
      map(isEmailNotTaken => {
        return isEmailNotTaken ? null : { emailTaken: true };
      })
    );
  };
}
