export interface JsonFormData {
  title?: string;
  align?: string;
  id?: string;
  buttonType1?: ButtonType;
  button1HTML?: string;
  buttonType2?: ButtonType;
  hideMainAction?: boolean;
  actionText?: string;
  actionText2?: string;
  text2Class?: string;
  elements: JsonFormElement[];

}

export enum ButtonType {
  icon = 'ICON',
  button = 'BUTTON'
}

export interface JsonFormElement  {
  labelWidthClass?: string;
  recipientClass?: string;
  uploadUserId?: number;
  elementClass?: string;
  iconClass?: string;
  inline?: boolean,
  'text': string,
  'type': string,
  'name': string,
  'isDeletable'?: boolean,
  'id': string,
  'editable'?: boolean,
  'value'?: number | string | boolean,
  value2?: string | boolean,
  value3?: string | boolean,
  value4?: string | boolean,
  innerProp?: string,
  'propToRead'?: string, /* propieta da leggere in innerdata*/
  innerData?: any,
  mode?: string;
  json?: any;
  directory?: string;
  interAction?: any;
  options?: {name: string, value: string}[];
  isOnError?: boolean;
  errorMessage?: string
}

export interface DataResults {
  success: boolean,
  msg?: string,
  results: any[]
}
