import { Injectable, LOCALE_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  setLocale(locale: string) {
    this.locale = locale;
  }
  get getLocale() {
    return this.locale;
  }
}
