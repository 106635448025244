import {Component, inject} from '@angular/core';
import {CommonSharedModuleModule} from '../../../../modules/common-shared-module/common-shared-module.module';
import {JsonFormData} from '../../../../../interfaces/jsonFormData';
import {ApiService} from '../../../../services/api.service';
import {JsonEditorComponent} from "../../../../components/json-editor/json-editor.component";
import {firstValueFrom, lastValueFrom} from "rxjs";
import {CommunicationService} from "../../../../services/communication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonSharedModuleModule, JsonEditorComponent, TranslateModule],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  router = inject(Router);
  actionToken: string = '';
  formData: JsonFormData = {elements: []};
  newPassForm: JsonFormData = {elements: []};
  communicationService = inject(CommunicationService);
  passDonMatch: string = '';
  passMissing: string = '';
  addNewPass: string = '';
  repeatPAss: string = '';
  confirmPassword: string = ''
  constructor(private translate: TranslateService, private route: ActivatedRoute, private apiService: ApiService) {

    this.setMessages();

    this.route.queryParams
      .subscribe(params => {
          console.log(params); // { orderby: "price" }
          this.actionToken = params['token'];
        }
      );

    this.setForm();

  }

  async setForm() {
    // this.formData.title = 'Resetta la tua password';
    this.formData.align = 'INLINE';
    this.formData.actionText = 'Invia la richiesta';
    this.formData.elements = [
      {
        'labelWidthClass': '',
        'text': await lastValueFrom(this.translate.get('ENTERYOUREMAIL', )).then((r) => r),
        'type': 'input',
        'name': 'email',
        'id': 'email',
        'inline': true
      }
    ];
  }

  async setMessages() {
    this.passDonMatch = await lastValueFrom(this.translate.get('PASSDONTMATCH', )).then((r) => r);
    this.passMissing = await lastValueFrom(this.translate.get('PASSMISSING', )).then((r) => r);
    this.addNewPass = await lastValueFrom(this.translate.get('ADDNEWPASS', )).then((r) => r);
    this.repeatPAss = await lastValueFrom(this.translate.get('REPEATPASS', )).then((r) => r);
    this.confirmPassword = await lastValueFrom(this.translate.get('CONFIRMPASS', )).then((r) => r);

    this.newPassForm.align = 'INLINE';
    this.newPassForm.actionText = this.confirmPassword;
    this.newPassForm.elements = [
      {
        'labelWidthClass': '',
        'text': '',
        'type': 'hidden',
        'name': 'cc',
        'id': 'cc',
        'inline': true,
        value: this.actionToken
      },
      {
        'labelWidthClass': 's',
        'text': this.addNewPass,
        'type': 'password',
        'name': 'password',
        'id': 'password',
        'inline': true
      },
      {
        'labelWidthClass': '',
        'text': this.repeatPAss,
        'type': 'password2',
        'name': 'password2',
        'id': 'password2',
        'inline': true,
        'options': [{name: 'notMatchText', value: this.passDonMatch }]
      }
    ];
  }
  action(ev: any) {
    console.log("email", ev);
    if (!ev.email || ev.email === '') {
      alert('Inserisci la tua email');
      return;
    }

    firstValueFrom(this.apiService.makeApiCall('/users/resetPassword', 'POST', {email: ev.email})).then((res: any) => {
      if (res.success) {
        this.communicationService.showSuccessToast('Message', res.msg);
        setTimeout(() => {
          this.router.navigateByUrl('login');
        })
      } else {
        this.communicationService.showErrorToast('Message', res.msg);
      }

    })

  }

  setNewPass(ev: any) {
    debugger;
    if (ev.password === '') {
      alert('Manca la password');
      return;
    }

    if (ev.password !== ev.password2) {
      alert('Le password non coincidono');
      return;
    }

    firstValueFrom(this.apiService.makeApiCall('/users/resetNewPass', 'POST', {token: ev.cc, newPass: ev.password})).then((res: any) => {
      if (res.success) {
        this.communicationService.showSuccessToast('Message', res.msg);
        this.router.navigateByUrl('/login');
      } else {
        this.communicationService.showErrorToast('Message', res.msg);
      }
    });

  }
}
