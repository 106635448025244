<div class="dropdown ms-1 topbar-head-dropdown" *ngIf="userIsLogged">
  <button type="button" (click)="showCashers =! showCashers " class="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="fas fa-wallet"></i>
  </button>


  <div [ngClass]="{'show': showCashers}" class="isFixed dropdown-menu dropdown-menu-end">
    <!-- item-->
    <button (click)="openDepositNow()" class="btn btn-success btn-sm withdrawable" >{{'DEPOSIT' | translate}}</button>
    <button (click)="openWithdrawNow()"  class="btn btn-primary btn-sm withdrawable" >{{'WITHDRAWABLE' | translate}}: {{myWalletData?.GPTB}} GPTB</button>
    <button class="btn btn-warning btn-sm withdrawable" routerLink="auth/cashier">{{'CONVERTABLE' | translate}} {{myWalletData?.cash}} pEur</button>

    <a class="dropdown-item notify-item language py-2" title="Cash L.1">
      <span class="badge bs-secondary me-2">L.2</span>
      <span class="align-middle"> {{myWalletData?.cashLevel1 | currency:'pEur'}} </span>
    </a>

    <a class="dropdown-item notify-item language py-2" title="Cash L.2">
      <span class="badge bs-secondary me-2">L.3</span>
      <span class="align-middle"> {{myWalletData?.cashLevel2 | currency:'pEur'}}</span>
    </a>

    <a class="dropdown-item notify-item language py-2" title="Cash L.3">
      <span class="badge bs-secondary me-2">L.4</span>
      <span class="align-middle"> {{myWalletData?.cashLevel3 | currency:'pEur'}}</span>
    </a>

    <a class="dropdown-item notify-item language py-2" title="Cash L.4">
      <span class="badge bs-secondary me-2">L.5</span>
      <span class="align-middle"> {{myWalletData?.cashLevel4 | currency:'pEur'}}</span>
    </a>

    <a  class="dropdown-item notify-item language py-2" title="Cash L.5">
      <span class="badge bs-secondary me-2">L.6</span>
      <span class="align-middle"> {{myWalletData?.cashLevel5 | currency:'pEur'}}</span>
    </a>

    <a  class="dropdown-item notify-item language py-2" title="Cash L.6">
      <span class="badge bs-secondary me-2">L.7</span>
      <span class="align-middle"> {{myWalletData?.cashLevel6 | currency:'pEur'}}</span>
    </a>

    <a  class="dropdown-item notify-item language py-2" title="Cash L.7">
      <span class="badge bs-secondary me-2">L.8</span>
      <span class="align-middle"> {{myWalletData?.cashLevel7 | currency:'pEur'}}</span>
    </a>

    <a class="dropdown-item notify-item language py-2" title="Cash L.8">
      <span class="badge bs-secondary me-2">L.9</span>
      <span class="align-middle"> {{myWalletData?.cashLevel8 | currency:'pEur'}}</span>
    </a>

    <a class="dropdown-item notify-item language py-2" title="Cash L.9">
      <span class="badge bs-secondary me-2">L.10</span>
      <span class="align-middle"> {{myWalletData?.cashLevel9 | currency:'pEur'}}</span>
    </a>
    <hr>
    <a *ngIf="myWalletData" class="dropdown-item notify-item language py-2" title="Cash L.9">
      <span class="badge bg-success me-2">TOTAL</span>
      <span class="align-middle"> {{

      +myWalletData.cash +
      +myWalletData.cashLevel1 +
      +myWalletData.cashLevel2 +
      +myWalletData.cashLevel3 +
      +myWalletData.cashLevel4 +
      +myWalletData.cashLevel5 +
      +myWalletData.cashLevel6 +
      +myWalletData.cashLevel7 +
      +myWalletData.cashLevel8 +
      +myWalletData.cashLevel9 | currency:'pEur'}}</span>
    </a>

  </div>
</div>
<app-auto-modal id="toDeposit" [hideOpenButton]="true" [openTheModal]="openDeposit" [openButtonText]="'DEPOSIT' | translate" [dataJson]="{text2Class: 'withdrawable', title: 'DEPOSIT' | translate, buttonType1: ButtonType.button, id: 'deposit', elements: []}" [model]="'AUTO'">
  <app-payment-popup *ngIf="openDeposit" [view]="'DEPOSIT'"></app-payment-popup>
</app-auto-modal>

<app-auto-modal *ngIf="myWalletData?.cash !== null" [hideOpenButton]="true" [openTheModal]="openWithdraw" [openButtonText]="('CONVERTABLE' | translate) + ': ' + myWalletData!.cash.toString() + ' pEur'" [dataJson]="{title: 'PRELEVA' | translate, text2Class: 'withdrawable btn-warning', buttonType1: ButtonType.button,
    elements: []}" [model]="'AUTO'">
  <app-payment-popup *ngIf="openWithdraw" [view]="'WITHDRAW'"></app-payment-popup>
</app-auto-modal>
