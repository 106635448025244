import {Component, Input} from '@angular/core';
import {CurrencyPipe, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-calculator',
  standalone: true,
  imports: [
    CurrencyPipe,
    FormsModule,
    NgIf
  ],
  templateUrl: './calculator.component.html',
  styleUrl: './calculator.component.scss'
})
export class CalculatorComponent {

  @Input() gptBet: number = 0;
  @Input() gptQuota: number = 0;
  banco: number = 0;
  extQuota: number = 0;

  vincitaGpt() {
    return (this.gptBet * this.gptQuota) - this.gptBet;
  }

  vincitaExt() {
    return (this.banco * this.extQuota) - this.banco;
  }

  matchedGpt() {
    return this.vincitaGpt() - this.vincitaExt();
  }

  matchedExt() {
    return this.banco - this.gptBet;
  }

}
