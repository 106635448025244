import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ConfirmationResponse} from '../../interfaces/communication';
import {EventTypes, ToastEvent} from '../../interfaces/toast';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  private availableDataToBeSaved: boolean = false;
  private _isLoadingOrSavingSubject = new BehaviorSubject<boolean>(false);
  private _confirmationSubject = new BehaviorSubject<ConfirmationResponse | null>(null);

  toastEvents: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  get isLoadingSaving() {
    return this._isLoadingOrSavingSubject.asObservable();
  }
  isLoadSet(value: boolean) {
    this._isLoadingOrSavingSubject.next(value);
  }
  get confirmation$(): Observable<ConfirmationResponse | null> {
    return this._confirmationSubject.asObservable();
  }

  get dataToBeSaved() {
    return this.availableDataToBeSaved;
  }

  set dataToBeSaved(status: boolean) {
    this.availableDataToBeSaved = status;
  }

  confirm(actionId: string) {
    debugger;
    this._confirmationSubject.next({ actionId, confirmed: true });
  }

  cancel(actionId: string) {
    debugger;
    this._confirmationSubject.next({ actionId, confirmed: false });
  }

  reset() {
    this._confirmationSubject.next(null);
  }

  /**
   * Show success toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showSuccessToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Success,
    });
  }

  /**
   * Show info toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showInfoToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Info,
    });
  }

  /**
   * Show warning toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showWarningToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Warning,
    });
  }

  /**
   * Show error toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  showErrorToast(title: string, message: string) {
    this._toastEvents.next({
      message,
      title,
      type: EventTypes.Error,
    });
  }

}
