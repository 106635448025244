import {AfterViewInit, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {SubscriptionPlan} from "../interfaces/subscription";
import {CurrencyPipe, DecimalPipe, JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {Router, RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {StoreService} from "../../../services/store.service";
import {environment} from "../../../../environments/environment";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-single-subscription-present',
  standalone: true,
  imports: [
    NgIf,
    CurrencyPipe,
    NgClass,
    RouterLink,
    NgForOf,
    TranslateModule,
    DecimalPipe,
    JsonPipe
  ],
  templateUrl: './single-subscription-present.component.html',
  styleUrl: './single-subscription-present.component.scss'
})

export class SingleSubscriptionPresentComponent implements AfterViewInit {
  router = inject(Router);

  @Input() subscriptionPlan?: SubscriptionPlan;
  @Input() gotDiscount: number = 0;
  @Output() action = new EventEmitter<SubscriptionPlan>();
  @Output() renew = new EventEmitter<SubscriptionPlan>();

  storeService = inject(StoreService);
  apiService = inject(ApiService);

  renewDetails: any = {};
  renewLoading: boolean = false;
  ngAfterViewInit() {
    if (this.userLogged && this.userSubscription!.id === this.subscriptionPlan!.id) {
      this.renewLoading = true;
      this.apiService.authorizedApiCall('/users/renewalDetails', 'POST', {}).then((res: any) => {
        this.renewDetails = res.results.results[0];
        this.renewLoading = false;
      })
    }
  }

  arrayIt(num: number) {
    return Array(num).fill(0).map((x,i) => i)
  }

  userLang() {
    return localStorage.getItem(environment.storageLangId) || 'it'
  }

  get userLogged() {
    return this.storeService.getCurrentStore().authDone && this.storeService.getCurrentStore().authUser.userId > 0
  }

  async actionow(plan: SubscriptionPlan) {
    if (!this.storeService.getCurrentStore().authDone || this.storeService.getCurrentStore().authDone && !this.storeService.getCurrentStore().authUser.userId) { await this.router.navigateByUrl('/register'); return;}
    this.action.emit(plan)
  }

  async renewPlan(plan: SubscriptionPlan) {
    if (!this.storeService.getCurrentStore().authDone || this.storeService.getCurrentStore().authDone && !this.storeService.getCurrentStore().authUser.userId) { await this.router.navigateByUrl('/register'); return;}
    this.renew.emit(plan)
  }

  get userSubscription() {
    return this.storeService.getCurrentStore().userSubscription;
  }

}
