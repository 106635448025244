export interface SubscriptionPlan {
  id: number,
  active: boolean,
  nameVar: string,
  descriptionVar: string,
  fullPrice: number,
  finalPrice: number,
  surplusPercentFrom: number,
  surplusPercentTo: number,
  duration: ContractDuration,
  maxBet: number,
  referralEarningPercent: number,
  stepToCashOut: number,
  currency: string,
  class: string;
  valueLevel: number
}

export interface SubscriptionPlanData extends SubscriptionPlan {
  endingDate: Date,
  startingDate: Date
}

export interface UserSubscription {
  id: number,
  userId: number,
  subscriptionPlanId: number,
  subscriptionPlanData: any,
  startingDate: Date,
  endingDate: Date | null,
  transactionId: number
}

export enum ContractDuration {
  'weekly' = 'WEEKLY',
  'monthly' = 'MONTHLY',
  'yearly' = 'YEARLY'
}
