<div *ngIf="!isLogged" class="container z-depth-1">


  <!--Section: Content-->
  <section class="dark-grey-text p-xl-5 pt-sm-0">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-5 mb-4 mb-md-0">

        <div class="text-center">
          <img src="assets/images/gptBetLogo.png" class="img-fluid mx-auto" alt="GPT BET" style="max-height: 250px;">
        </div>

      </div>

      <!-- Grid column -->
      <div class="col-md-7 mb-lg-0 mb-4 d-flex align-items-center">
        <div class="container">
          <!-- Grid column -->
          <div class="col-md-8">
        <!-- Form -->
        <form  class="mx-auto">
          <div>
            <div class="form-group">
              <label for="signin-email">Email</label>
              <input #email id="signin-email" name="signin-email" type="email" class="form-control">
            </div>
            <div class="form-group">
              <label for="signin-password">Password</label>
              <input #password id="signin-password" name="signin-password" type="password" class="form-control">
            </div>
            <div class="form-check">
              <input #rememberMe class="form-check-input" type="checkbox" id="remember-me">
              <label class="form-check-label" for="remember-me">{{'REMINDME' | translate}}</label>
            </div>
            <button type="submit" class="btn btn-primary mt-3" (click)="doLogin($event)" >{{'SEND' | translate}}</button>
          </div>

          <ul>
            <li><a routerLink="/resetPassword">{{'FORGOTTENPASS' | translate}}</a></li>
            <li><a routerLink="/register">{{'REGISTER' | translate}}</a></li>
          </ul>


        </form>

          </div>
        </div>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </section>
  <!--Section: Content-->

</div>

<div *ngIf="isLogged" class="container my-5 z-depth-1 text-center">
  <h1>Logout</h1>
  <p>{{'LOGOUTEXT' | translate}} <a (click)="doLogout()" href="#">logout</a></p>
  <div class="view">
    <img src="assets/images/gptBetLogo.png" style="max-width: 300px;" alt="GPT Bet">
  </div>
</div>

