import {Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {StoreService} from "../../../services/store.service";
import {ApiService} from "../../../services/api.service";
import {SubscriptionPlan} from "../interfaces/subscription";
import {SingleSubscriptionPresentComponent} from "../single-subscription-present/single-subscription-present.component";
import {NgForOf, NgIf} from "@angular/common";
import {JsonEditorComponent} from "../../json-editor/json-editor.component";
import {PaymentPopupComponent} from "../payment-popup/payment-popup.component";
import {Modal} from "bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ConfirmationResponse} from "../../../../interfaces/communication";
import {lastValueFrom} from "rxjs";
import {CommunicationService} from "../../../services/communication.service";
import {CommonSharedModuleModule} from "../../../modules/common-shared-module/common-shared-module.module";
import {Router} from "@angular/router";
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-subscription-viewer',
  standalone: true,
  imports: [
    SingleSubscriptionPresentComponent,
    NgIf,
    NgForOf,
    JsonEditorComponent,
    PaymentPopupComponent,
    TranslateModule,
    CommonSharedModuleModule
  ],
  templateUrl: './subscription-viewer.component.html',
  styleUrl: './subscription-viewer.component.scss'
})
export class SubscriptionViewerComponent implements OnInit {
  @Input() method: string = '';
  @ViewChild('modal') modal: ElementRef | undefined;

  bsModal: any;

  storeService = inject(StoreService);
  apiService = inject(ApiService);
  communicationService = inject(CommunicationService);
  router = inject(Router);

  subscriptionPlans: SubscriptionPlan[] = [];

  paymentAwaiting: boolean = false;

  popupMode = 'BUYPLAN';

  gotDiscount = 0;

  constructor() {


  }

  ngOnInit() {
    this.getData();
    this.storeService.getData().subscribe((res) => {
      if (res.actionId === 'authUser' || res.actionId === 'authDone') {
        if (res.authDone) {
         this.getDiscount();
        }
      }
    })

  }

  async getData() {
    this.subscriptionPlans = await this.apiService.freeCachedApiCall('/users/getSubscriptionPlans', 'POST', {}, 180000)
      .then((res: any) => {
        return res.results;
      });
  }

  async getDiscount() {
    if (!this.storeService.getCurrentStore().authUser.isLogged)
    { return; }
    await this.apiService.freeCachedApiCall('/users/calculateDiscount', 'POST', {}, 180000)
      .then((res: any) => {
        console.log('got discount', res)
        this.gotDiscount = res;
      });
  }

  planId: number = 0;
  subscriptionPlan?: SubscriptionPlan;
  openModal(ev: SubscriptionPlan) {
    this.popupMode = 'BUYPLAN';
    this.bsModal = new Modal(this.modal!.nativeElement);
    this.subscriptionPlan = {...ev}
    this.bsModal.show();
  }

  renewPlan(ev: SubscriptionPlan) {

    this.popupMode = 'RENEWTHEPLAN';
    this.bsModal = new Modal(this.modal!.nativeElement);
    this.subscriptionPlan = {...ev}
    this.bsModal.show();
  }

  closeModal() {
    this.bsModal.hide();
    this.confirmAction('confirmPayment', null);
  }
  modalInstance: any;

  confirmAction(actionId: string, data: any) {

    debugger;
    const modalElement = document.getElementById(actionId);
    if (modalElement) {
      this.modalInstance = new bootstrap.Modal(modalElement);
      this.modalInstance.show();
    }

    const subscription = this.communicationService.confirmation$.subscribe((result: ConfirmationResponse | null) => {
      if (result !== null && result.actionId === actionId) {
        this.modalInstance.hide();
        subscription.unsubscribe();
        this.communicationService.reset();
        if (result.confirmed) {
          console.log(`Azione confermata: ${actionId}`);
          // Esegui l'azione qui in base all'identificatore dell'azione

          if (actionId === 'confirmPayment') {
            this.makePurchase();
          }

        } else {
          console.log(`Azione annullata: ${actionId}`);
        }
      }
    });
  }

  async makePurchase() {
    this.paymentAwaiting = true;
    lastValueFrom(this.apiService.makeApiCall('/payment/purchasePlan', 'POST', {
      planId: this.subscriptionPlan?.id
    })).then((res: any) => {
      if (res.success) {
        this.storeService.getAuthUser();
        this.paymentAwaiting = false;
        const myModal = document.getElementById('closeModalupgrade');
        myModal!.click();
      } else {
        alert('error');
        console.log('Error', res)
      }

    });
  }
}
