import {AfterViewInit, Component, inject, Input} from '@angular/core';
import { navigationTree, StoreService} from '../../services/store.service';
import {FullStore} from '../../../interfaces/store';
import {NavigationItem} from '../../../interfaces/navigation';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
@Component({
    selector: 'app-lateral-menu',
    templateUrl: './lateral-menu.component.html',
    styleUrls: ['./lateral-menu.component.scss'],
    standalone: true,
  imports: [NgFor, RouterLinkActive, RouterLink, NgIf, TranslateModule, NgClass]
})
export class LateralMenuComponent implements AfterViewInit {

  @Input() position: string = 'LATERAL'
  children: NavigationItem[] = [];
  currentUrl: string = '';
  translate = inject(TranslateService);
  constructor(private store: StoreService, private router: Router) {
    this.store.getData().subscribe((res: FullStore) => {
      this.currentUrl = res.navigation?.currentUrl!;
      this.children = [];
      this.createNavigation();
    })
  }

  ngAfterViewInit() {

  }

  createNavigation() {
    this.children = navigationTree.find((el) => el.path === this.currentUrl.substring(1) )?.children || [];
    if (this.children?.length <= 0) {
      this.getFatherNavigation();
    }
  }
  get routeUrl() {
    const segments = this.router.url.split('/');
    let found = '';
    segments.forEach((segment: string) => {
      if (found === '' && segment && segment !== '') {
        found = segment;
      }
    });
    return found;
  }
  getFatherNavigation() {
    debugger;
    const routes = this.router.url.split('/');
    this.children = this.store.getCurrentRoute(navigationTree, routes[1])?.children!;
  }

  getPermission(route: NavigationItem): boolean {
    if (route.permissionNeeded?.permissionName) {
      return this.store.verifyRequestedPermission(route.permissionNeeded.permissionName, route.permissionNeeded.permissions);
    }
    return true;
  }
}
