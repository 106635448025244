
<button *ngIf="dataJson && dataJson?.buttonType1 === ButtonType.button && !hideOpenButton" (click)="openModalOut()"
        [id]="'openModal'+dataJson.id" type="button" [ngClass]="dataJson.text2Class"
        class="btn btn-success btn-sm" data-bs-toggle="modal"
        [attr.data-bs-target]="'#' + dataJson.id" [innerHTML]="openButtonText || 'APRI' | translate">
</button>
<ng-container *ngIf="dataJson && dataJson?.buttonType1 === ButtonType.icon && !hideOpenButton">
  <button type="button" class="btn" data-bs-toggle="modal" [id]="'openModal'" (click)="openModalOut()"
          [attr.data-bs-target]="'#' + dataJson.id" [innerHTML]="dataJson.button1HTML || 'APRI' | translate">
  </button>
</ng-container>


<div *ngIf="model === 'JSON' && dataJson" class="modal fade" [ngClass]="modalClass" [id]="dataJson.id" tabindex="-1" aria-labelledby="autoModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" [id]="dataJson.id">{{dataJson.title}}</h5>
        <span (click)="close()"></span>
        <button type="button" id="closeModal{{dataJson.id}}" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeButton></button>
      </div>
      <div class="modal-body p-1 p-md-4">
        <div class="row gx-0">
          <div class="col-12">
            <app-json-editor [formConfig]="dataJson" (saveForm)="saveFormOut($event)" (actionForm)="actionFormOut($event)" (innerActionOut)="innerActionOut($event)"></app-json-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="model === 'AUTO' && dataJson" data-bs-backdrop='static' class="modal fade" [ngClass]="modalClass" [id]="dataJson.id" tabindex="-1" aria-labelledby="autoModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" [id]="dataJson.id">{{dataJson.title}}</h5>
        <span (click)="close()"></span>
        <button (click)="close()" type="button" id="closeModal{{dataJson.id}}" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeButton></button>
      </div>
      <div class="modal-body p-1 p-md-4">
        <div class="row gx-0">
          <div class="col-12">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

