<div class="container z-depth-1">


  <!--Section: Content-->
  <section class="dark-grey-text p-xl-5 pt-sm-0">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-5 mb-4 mb-md-0 d-flex align-items-center">

        <div class="view">
          <img src="assets/images/gptBetLogo.png" class="img-fluid" alt="GPT BET">
        </div>

      </div>

      <!-- Grid column -->
      <div class="col-md-7 mb-lg-0 mb-4 d-flex align-items-center">

          <app-registra-user *ngIf="view === 'REGISTRATION'" (saveForm)="gotoUrl($event)"></app-registra-user>

          <ng-container *ngIf="view === 'CONFIRMATION'">
            <h1>{{'EMAIL-CONFIRMATION-TITLE' | translate}}</h1>
            <p>Ti è stata inviata una mail, clicca nel link per confermare la corretta ricezione</p>
          </ng-container>


      </div>

    </div>

  </section>

</div>
