import {AfterViewInit, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {StoreService} from "../../../services/store.service";
import {Router} from "@angular/router";
import {CurrencyPipe, DecimalPipe, JsonPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ContractDuration, SubscriptionPlan} from "../interfaces/subscription";
import {FormsModule} from "@angular/forms";
import {CommunicationService} from "../../../services/communication.service";
import {firstValueFrom, lastValueFrom} from "rxjs";
import {Socket} from "ngx-socket-io";
import {map} from "rxjs/operators";
import {socketAction} from "../../../../interfaces/socket";
import {CommonSharedModuleModule} from "../../../modules/common-shared-module/common-shared-module.module";
import {ConfirmationResponse} from "../../../../interfaces/communication";
import * as bootstrap from "bootstrap";


@Component({
  selector: 'app-payment-popup',
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    JsonPipe,
    DecimalPipe,
    NgForOf,
    NgClass,
    FormsModule,
    CurrencyPipe,
    CommonSharedModuleModule
  ],
  templateUrl: './payment-popup.component.html',
  styleUrl: './payment-popup.component.scss'
})
export class PaymentPopupComponent implements AfterViewInit, OnChanges {
  @Input() subscriptionPlan?: SubscriptionPlan;
  @Input() view?: string = 'DEPOSIT'
  @Input() discount: number = 0;
  @Output() actionOut = new EventEmitter<string>();

  communicationService = inject(CommunicationService);
  translate = inject(TranslateService);
  socket = inject(Socket);

  apiService = inject(ApiService);
  sanitizer = inject(DomSanitizer);
  storeService = inject(StoreService);
  router = inject(Router);

  showFrame: boolean = false;
  frameUrl: SafeResourceUrl = '';

  planDetails?: SubscriptionPlan
  contractDuration = 'MONTHLY';

  modalInstance: any;

  paymentAsked: boolean = false;
  paymentAwaiting: boolean = false;

  invoicing: boolean = false;
  amountDeposit: number = 0;

  amountWithdraw: number = 0;

  exchangePEurToGPTB = 0;
  exchangeGPTBToUSDT = 0;

  constructor(){

    this.socket.fromEvent('deposit').pipe(map((data: any) => data.data.actions)).subscribe( (actions: socketAction[]) => {

      actions.forEach(async (sa) => {
        if (sa.actionName === 'depositDone') {
          this.communicationService.showInfoToast('DEPOSIT', 'DEPOSITMESSAGE');
        }
        if (sa.actionName === 'newFullWallet') {
          this.storeService.setMyData( 'wallet', {...sa.value});
          this.amountDeposit = 0;
          this.paymentAsked = false;
          this.paymentAwaiting = false;
          this.showFrame = false;
          this.actionOut.emit('depositDone');
        }

      })
    });

    this.socket.fromEvent('purchase').pipe(map((data: any) => data.data.actions)).subscribe( (actions: socketAction[]) => {

      actions.forEach(async (sa) => {
        if (sa.actionName === 'purchaseDone') {
          this.communicationService.showInfoToast('DEPOSIT', 'DEPOSITMESSAGE');
        }
        if (sa.actionName === 'newFullWallet') {
          this.storeService.setMyData( 'wallet', {...sa.value});
          this.amountDeposit = 0;
          this.paymentAsked = false;
          this.paymentAwaiting = false;
          this.showFrame = false;
          this.actionOut.emit('purchaseDone');
        }

      })
    })

  }

  async ngOnChanges(changes: SimpleChanges) {
    this.showFrame = false;

    if (this.view === 'WITHDRAW') {
      await this.apiService.freeCachedApiCall('/users/peurTogpt', 'POST', {}, 360000)
        .then((res: any) => {
          if (res.success) {
            const eu = res.results.find((el: any) => el.nameProperty === 'exchangePEurToGPTB');
            const usdt = res.results.find((el: any) => el.nameProperty === 'exchangeGPTBToUSDT');

            this.exchangePEurToGPTB = eu['valueProperty'];
            this.exchangeGPTBToUSDT = usdt['valueProperty'];
          }
        });
    }
  }

  get getExchangeRate() {
    return +this.exchangePEurToGPTB;
  }

  renewDetails: any = {}
  async ngAfterViewInit() {
    if (this.view === 'RENEWTHEPLAN') {
      // I must get the renewal details to be displayed, so the user can see if he is purchasing the plan before the expiring date
      this.apiService.authorizedApiCall('/users/renewalDetails', 'POST', {}).then((res: any) => {
        this.renewDetails = res.results.results;
      })
    }
    debugger
    if (!this.storeService.getCurrentStore().authDone || this.storeService.getCurrentStore().authDone && !this.storeService.getCurrentStore().authUser.userId) {
      await this.router.navigateByUrl('/register');
      return;
    }
    if (this.storeService.getCurrentStore().authDone && this.storeService.getCurrentStore().authUser.userId && this.subscriptionPlan) {
      this.planDetails = this.subscriptionPlan;
    }
    // console.log(this.storeService.getCurrentStore().authDone, this.storeService.getCurrentStore().authUser.userId)
  }

  async testDeposit() {
    return
    this.apiService.makeApiCall('/users/testDeposit', 'POST', {}).subscribe((r: any) => {
      console.log('resss', r)
    });
  }
  async depositOtherCoins() {
    debugger;
    this.paymentAsked = true;
    this.apiService.makeApiCall('/payment/createInvoiceNow', 'POST', {
      userId: this.storeService.getCurrentStore().authUser.userId,
      username: this.storeService.getCurrentStore().authUser.authUserData.username,
      description: 'DEPOSIT from user: ' + this.storeService.getCurrentStore().authUser.userId + ' Username: '
        + this.storeService.getCurrentStore().authUser.authUserData.username + ' of ' + this.amountDeposit + ' EUR exchanged @' + this.getExchangeRate + ' ' + (this.amountDeposit * this.getExchangeRate),
      amount: this.amountDeposit
    }).subscribe({
      next: (res: any) => {
        this.showFrame = true;
        this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.invoice_url);
      },
      error: (err: any) => {
        alert('error');
        console.log('Error', err);
      }
    })
  }

  getTooLong: any;
  async launchPayment() {

    this.paymentAwaiting = true;
    // start timeout if the awaiting it gets too long
    this.getTooLong = setTimeout(async () => {
      const message = await lastValueFrom(this.translate.get('USERPROFILE')).then((r) => r);
      this.communicationService.showInfoToast('Info toast title', 'This is an info toast message.');
    }, 180000)
  }

  get getAuthUser() {
    return this.storeService.getCurrentStore().authUser
  }
  get userWallet() {
    return this.storeService.getCurrentStore().wallet;
  }
  get spendableMoney() {
    return +this.userWallet.cash + +this.userWallet.cashLevel1 + +this.userWallet.cashLevel2 + +this.userWallet.cashLevel3;
  }
  async buyPlan() {
    debugger;
    if (!this.subscriptionPlan) {return;}
    if (+this.spendableMoney < +this.subscriptionPlan.finalPrice) {
      const title = await firstValueFrom(this.translate.get('NOTENOUGHTMONEYTITLE')).then(r => r);
      const text = await firstValueFrom(this.translate.get('NOTENOUGHTMONEYTEXT')).then(r => r);
      this.communicationService.showErrorToast(title, text);
      return;
    }
  }

  confirmAction() {
    this.actionOut.emit('confirmPurchase');
  }

  confirmNow(actionId: string, data: any) {
    debugger;

    const modalElement = document.getElementById(actionId);
    if (modalElement) {
      this.modalInstance = new bootstrap.Modal(modalElement);
      this.modalInstance.show();
    }

    const subscription = this.communicationService.confirmation$.subscribe((result: ConfirmationResponse | null) => {
      if (result !== null && result.actionId === actionId) {
        this.modalInstance.hide();
        subscription.unsubscribe();
        this.communicationService.reset();
        if (result.confirmed) {
          console.log(`Azione confermata: ${actionId}`);
          // Esegui l'azione qui in base all'identificatore dell'azione

          if (actionId === 'withdraw') {
            this.withdraw();
          }
        }
      }
    })

  }


  withdraw() {

  }


  protected readonly ContractDuration = ContractDuration;
}
