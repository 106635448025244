
<table>
  <tr>
    <td><img *ngIf="freeDocList[0]" class="rounded-3" style="width: 150px;" src="{{environment.domain}}/{{environment.mainEndpoint}}/users/download?userId={{userId}}&url=uploads/{{userId}}/{{directory}}/{{freeDocList[0]}}">
    </td>
    <td>
      <input *ngIf="(+filesLimit > freeDocList.length) || !filesLimit" type="file" (change)="onFileSelected($event)">
      <i *ngIf="+filesLimit <= freeDocList.length" (click)="confirmAction('deleteFile')" class="fas fa-times-circle ms-md-1 me-md-2 app-ico-secondary"></i>
    </td>
  </tr>
</table>


<table class="table table-bordered" *ngIf="showFiles && docList.length > 0">
  <thead>
  <tr>
    <th scope="col" class="small">File</th>
    <th scope="col" class="small">Data</th>
    <th scope="col" class="small">Note</th>
    <th scope="col" class="small">Azione</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let doc of docList">
    <td>
      <a target="_Blank" href="{{environment.domain}}/{{environment.mainEndpoint}}/users/download?userId={{userId}}&docId={{doc.id}}&contractId={{contractId}}">{{doc.docUrl}}</a>
    </td>
    <td>
      {{ doc.lastUpdate | date:'short' }}

    </td>
    <td>
      <textarea [(ngModel)]="doc.publicNote" style="width: 300px;height:150px"></textarea>
    </td>
    <td>
      <i (click)="saveFileNotes(doc)" class="far fa-save ms-md-1 me-md-2 app-ico-primary"></i>
      <i (click)="confirmAction('deleteFile', doc)" class="fas fa-times-circle ms-md-1 me-md-2 app-ico-secondary"></i>
    </td>
  </tr>
  </tbody>
</table>

<table class="table table-bordered" *ngIf="(showDirectory && freeDocList.length > 0) || showDirectory && +filesLimit < freeDocList.length">
  <thead>
  <tr>
    <th scope="col" class="small">File</th>
    <th scope="col" class="small">Azione</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let doc of freeDocList">
    <td>
      <a target="_Blank" href="{{environment.domain}}/{{environment.mainEndpoint}}/users/download?userId={{userId}}">{{doc}}</a>
    </td>
    <td>
      <i (click)="confirmAction('deleteFile', doc)" class="fas fa-times-circle ms-md-1 me-md-2 app-ico-secondary"></i>
    </td>
  </tr>
  </tbody>
</table>

<div class="hideme fade" id="deleteFile" [attr.tabindex]="-1" [attr.aria-labelledby]="'deleteFile' + 'lLabel'" [attr.aria-hidden]="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="aaaLabel">Conferma cancellazione file</h1>
        <button type="button" class="btn-close" [attr.aria-label]="'Close'"  (click)="closeMe()"></button>
      </div>
      <div class="modal-body">Confermi la cancellazione di questo file? <span *ngIf="dataFileDeletion">({{dataFileDeletion['docUrl']}})</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeMe()">Chiudi</button>
        <button type="button" class="btn btn-primary" (click)="deleteFile()" >Conferma</button>
      </div>
    </div>
  </div>
</div>

