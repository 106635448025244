export const componentMap: any = {

  'home': () => import('../pages/home/home.component'),

  'admin': () => import('../pages/amministrazione/amministrazione.component'),
  'admin/setup': () => import('../pages/amministrazione/pages/setup/setup.component'),
  'admin/users': () => import('../pages/network/pages/gestione-utenti/gestione-utenti.component'),
  'admin/matches': () => import('../pages/amministrazione/pages/match-manager/match-manager.component'),

  'network': () => import('../pages/network/network.component'),
  'network/view': () => import('../pages/network/pages/naviga-network/naviga-network.component'),

  'auth': () => import('../pages/auth/dashboard/dashboard.component'),
  'auth/dashboard': () => import('../pages/auth/dashboard/dashboard.component'),
  'auth/myBets': () => import('../pages/auth/pages/my-bets/my-bets.page.component'),
  'auth/profile': () => import('../pages/user-profile/user-profile.component'),
  'auth/changePassword': () => import('../pages/user-profile/pages/change-password/change-password.component'),
  'auth/changeEmail': () => import('../pages/auth/pages/email-change/email-change.component'),
  'auth/cashier': () => import('../pages/auth/pages/cashier/cashier.component'),

  'login': () => import('../pages/login/login.component'),


}
export function importWrapper(path: string) {
  const importer = componentMap[path];
  if (!importer) {
    throw new Error(`Cannot find module '${path}'`);
  }
  return () => importer().then((c: any) => c[Object.keys(c)[0]]);
}
