import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LateralMenuComponent } from './components/lateral-menu/lateral-menu.component';
import {CommonModule, NgOptimizedImage, registerLocaleData} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {TokenInterceptor} from './interceptors/token.interceptors';
import {ToasterComponent} from './components/toaster/toaster.component';
import { ToastComponent } from './components/toaster/components/toast/toast.component';
import { ValidateactionComponent } from './pages/validateaction/validateaction.component';
import {CommonSharedModuleModule} from './modules/common-shared-module/common-shared-module.module';

import {AutoModalComponent} from "./components/auto-modal/auto-modal.component";
import {SocketIoConfig, SocketIoModule} from "ngx-socket-io";
import {environment} from "../environments/environment";
import {JsonEditorComponent} from "./components/json-editor/json-editor.component";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {LocaleService} from "./services/locale.service";

import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';

// Registra i dati di localizzazione per ciascuna lingua
registerLocaleData(localeIt);
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeEs);


const config: SocketIoConfig = { url: environment.domain, options: {
  path: environment.mainEndpoint + '/socket.io',
  extraHeaders: {
      Authorization: localStorage.getItem(environment.accessWebToken) || 'ass',
      publickey: localStorage.getItem('publickey') || 'none'
    }} };


export function HttpLoaderFactory(http: HttpClient) {
  return new InnerTranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, ToasterComponent, ToastComponent, ValidateactionComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    TopHeaderComponent,
    FooterComponent,
    LateralMenuComponent,
    CommonSharedModuleModule,
    NgOptimizedImage,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AutoModalComponent,
    SocketIoModule.forRoot(config),
    JsonEditorComponent
  ],
    providers: [
        HttpClient,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      { provide: LOCALE_ID, useValue: 'it-IT' },
      {
        provide: APP_INITIALIZER,
        useFactory: initApp,
        deps: [LocaleService],
        multi: true,
      }

    ],
  exports: [
    CommonModule,
    TranslateModule
  ],
    bootstrap: [AppComponent]
})

export class AppModule { }

export class InnerTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = "/assets/i18n/", public suffix: string = ".json") {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}` + '?r=' + Math.floor(Math.random() * 500));
  }
}




export function initApp(localeService: LocaleService) {
  return () => {
    const userLocale = navigator.language;
    localeService.setLocale(userLocale);
  };
}
