import {AfterViewInit, Component, Input} from '@angular/core';
import {CommunicationService} from '../../services/communication.service';

@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss'],
})
export class ConfirmAlertComponent implements AfterViewInit {
  @Input() idModal: string = 'defaultModal';
  @Input() modalText: string = 'Conferma';
  @Input() title: string = 'Conferma';

  constructor(private confirmationService: CommunicationService) {}


  ngAfterViewInit() {

  }
  onConfirmAction() {

    this.confirmationService.confirm(this.idModal);
  }

  onCancelAction() {
    this.confirmationService.cancel(this.idModal);
  }

}
