import {AfterViewInit, Component, ElementRef, HostListener, inject, ViewChild} from '@angular/core';
import {ApiService} from './services/api.service';
import {navigationTree, StoreService} from './services/store.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';
import {FullStore} from '../interfaces/store';
import {CommunicationService} from './services/communication.service';
import {EventTypes} from '../interfaces/toast';
import {NavigationItem} from '../interfaces/navigation';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";
import {Socket} from "ngx-socket-io";
import {map} from "rxjs/operators";
import {socketAction} from "../interfaces/socket";
import {UserWallet} from "../interfaces/wallet";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{
  @ViewChild('sidebar') sidebar?: ElementRef<HTMLElement>;

  socket = inject(Socket);

  @HostListener('window:resize')
  onResize() {
    this.updateSidebarWidth();
  }

  title = 'baseAdmin';
  currentNavigation: NavigationItem | null = null;
  showLoading: boolean = false;

  constructor(private translate: TranslateService, private communicationService: CommunicationService, private apiService: ApiService, private store: StoreService, private router: Router) {
    // setup the encription decription
    this.apiService.makeApiCall('/users/getSecretKey', 'POST', {}).subscribe((res: any) => {
      if (res.success) {
        this.store.setMyData('encryptedSecret', res.secretP2Key['valueProperty'] || 'NoEncryption', true);
      }
    });

    this.apiService.makeApiCall('/users/getGlobals', 'POST', {vars: ['eurusdt']}).subscribe((res: any) => {
      if (res.success) {
        this.store.setMyData('globals', {...res.results}, true);
      }
    });

    this.socket.fromEvent('walletChanges').pipe(map((data: any) => data.data.actions)).subscribe((actions: socketAction[])  => {

      actions.forEach((sa: any) => {
        if (sa.actionName === 'newFullWallet') {
          this.store.setMyData( 'wallet', {...sa.value});
        }
      })
    })

    const savedLang = localStorage.getItem(environment.storageLangId);
    if (savedLang) {
      this.translate.use(savedLang)
    }
    ///////////////////
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentNavigation = this.store.getCurrentRoute(navigationTree, event.urlAfterRedirects);
        this.store.setMyData('navigation.currentUrl', '/' + this.currentNavigation?.path);
        this.store.setMyData( 'navigation.currentNavigation', this.currentNavigation);
        console.log('Currently ok', this.currentNavigation);
        this.communicationService.dataToBeSaved = false;
      });
    this.store.getData().subscribe((res: FullStore) => {

      if ((this.currentNavigation !== null && this.currentNavigation.permissionNeeded) && (res.authDone && (!res.authUser?.isLogged || !res.authUser.userId))) {
        // user not logged
        debugger
        this.router.navigateByUrl('/home');
      }
    });


    // get authUserDetails. the token will be sent automatically
    this.store.getAuthUser();

    this.store.getData().subscribe((newStore: FullStore) => {
      if (newStore.actionId === 'authUser') {
        this.translate.use(newStore.authUser.langId);
        this.ngAfterViewInit();
        this.apiService.authorizedApiCall('/users/getMyWallet', 'POST', {}).then((res: any) => {
          if (res.success) {
            this.store.setMyData('wallet', res.results[0])
          }
        })

        this.apiService.authorizedCachedApiCall('/users/myBets', 'POST', {}, 60000).then((res: any) => {
          if (res.success) {
            this.store.setMyData('activeUserBets', res.results, true);
          }
        });
      }
    })

    this.socket.fromEvent('exchange').pipe(map((data: any) => data.data.actions)).subscribe((actions: socketAction[])  => {

      actions.forEach((sa: any) => {
        if (sa.actionName === 'reloadWallet') {
          this.apiService.authorizedApiCall('/users/getMyWallet', 'POST', {}).then((res: any) => {
            if (res.success) {
              this.store.setMyData('wallet', res.results[0])
            }
          })
        }
      })
    });


    this.communicationService.isLoadingSaving.subscribe((res) => {
      this.showLoading = res;
    })

  }

  async ngAfterViewInit() {
    this.updateSidebarWidth();
  }

  get getWidth() {
    return this.sidebar?.nativeElement?.offsetWidth || 1;
  }

  updateSidebarWidth() {
    debugger
    // da valutare se usare o no in quando elemento della barra non ha nativeElement la barra stessa dovrebbe esporre un
    // metodo per restituire la sua stessa dimensione, in quel caso la potrei leggere qui.
    // ho valutato che per ora non serve, inizialmente mi sarebbe servito per cetntrare la classe fixed che ora è sbilanciata
    // a causa della larghezza del la barra
    if (this.sidebar && this.sidebar.nativeElement) {
      this.store.getCurrentStore().navigation!.currentNavigation.width = this.sidebar.nativeElement.offsetWidth;
      // Qui puoi fare altro con la larghezza della sidebar
    }
  }

  call() {
    this.apiService.makeApiCall('/users/testme', 'GET', {})?.subscribe((res) => {
      console.log('Make call result', res);
    })
  }

  showToast(type: EventTypes) {
    switch (type) {
      case EventTypes.Success:
        this.communicationService.showSuccessToast('Success toast title', 'This is a success toast message.');
        break;
      case EventTypes.Warning:
        this.communicationService.showWarningToast('Warning toast title', 'This is a warning toast message.');
        break;
      case EventTypes.Error:
        this.communicationService.showErrorToast('Error toast title', 'This is an error toast message.');
        break;
      default:
        this.communicationService.showInfoToast('Info toast title', 'This is an info toast message.');
        break;
    }
  }

  isChild(currentNavigation: NavigationItem | null) {
    let isChild = false;

    if (!currentNavigation) {return isChild;}

    for (let navigation of navigationTree) {
      if (navigation.path === currentNavigation.path) {
        isChild = false;
        break;
      } else if (navigation && navigation.children && navigation.children.length > 0) {
        for (let child of navigation.children) {
          if (child.path === currentNavigation.path) {
            isChild = true;
            break;
          }
        }
      }
    }

    return isChild;

  }

}
