import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {StoreService} from "../../services/store.service";
import {DetailedMatch, Match, MatchTerm, UserBet} from "../match-editor/match.interface";
import {Socket} from "ngx-socket-io";
import {map} from "rxjs/operators";
import {socketAction} from "../../../interfaces/socket";
import {DatePipe, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {UserWallet} from "../../../interfaces/wallet";
import {CommonSharedModuleModule} from "../../modules/common-shared-module/common-shared-module.module";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ConfirmationResponse} from "../../../interfaces/communication";
import {CommunicationService} from "../../services/communication.service";
import {RouterLink} from "@angular/router";
import {CalculatorComponent} from "../calculator/calculator.component";
import {lastValueFrom} from "rxjs";
import * as bootstrap from 'bootstrap';



@Component({
  selector: 'app-match-shower',
  standalone: true,
  imports: [
    JsonPipe,
    NgForOf,
    DatePipe,
    NgIf,
    FormsModule,
    CommonSharedModuleModule,
    TranslateModule,
    RouterLink,
    CalculatorComponent
  ],
  templateUrl: './match-shower.component.html',
  styleUrl: './match-shower.component.scss'
})
export class MatchShowerComponent {

  apiService = inject(ApiService);
  storeService = inject(StoreService);
  socket = inject(Socket);
  communicationService = inject(CommunicationService);
  changes = inject(ChangeDetectorRef);
  translate = inject(TranslateService);

  myBets: UserBet[] = [];

  modalInstance: any;


  newBetContainer: NewBet[] = [];

  constructor() {
    this.getAvailableMatches();


    this.socket.fromEvent('newBetDone').pipe(map((data: any) => data.data.actions)).subscribe((actions: socketAction[])  => {
      // tutto da valutare mai neanche provato!
      // quando un livello nella scommessa cambia, se gente fanno delle azioni, se si chiude ecc
      actions.forEach((sa: any) => {
        if (sa.actionName === 'newBet') {
          const oldData = this.storeService.getData().value.activeUserBets;
          const oldBet = oldData.find((el: any) => el.matchId === sa.value.id)
          const currentBet = sa.value;
          // sa.value.userBetAmount
          if (!oldBet) {
            oldData.push(currentBet);
            this.storeService.setMyData('activeUserBets', oldData);
          } else {
            oldBet.betAmount = +oldBet.betAmount + +sa.value.betAmount;
          }

          this.changes.markForCheck();
          this.changes.detectChanges();
        }
      })
    })

    this.socket.fromEvent('matchChanges').pipe(map((data: any) => data.data.actions)).subscribe((actions: socketAction[])  => {
      // tutto da valutare mai neanche provato!
      // quando un livello nella scommessa cambia, se gente fanno delle azioni, se si chiude ecc
      actions.forEach((sa: any) => {
        if (sa.actionName === 'matchChanged') {
          this.setMatchChanges(sa.value)
        }
        if (sa.actionName === 'betAmountChanged') {
          const toEdit = this.availableMatches!.find(el => el.id === sa.value.matchId )!;
          toEdit.currentTotalBettingAmount = sa.value.newCurrentTotalBettingAmount;
          console.log('New Update', sa);

          debugger;
          // match.currentTotalBettingAmount! += +match.betFormModel!.amount!
          const oldData = this.storeService.getData().value.activeUserBets;
          const currentBet = oldData.find((el: any) => el.matchId === sa.value.matchId);
          // sa.value.userBetAmount
          if (currentBet) {
            currentBet.betAmount += +sa.value.userBetAmount;
            this.storeService.setMyData('activeUserBets', oldData);
          }

          this.changes.markForCheck();
          this.changes.detectChanges();
        }
      })
    })

  }

  getAvailableMatches() {

    this.apiService.freeCachedApiCall('/users/getAvailableMatches', 'POST', {}, 180000)
      .then((res: {success: boolean, results: Match[]}) => {
        // if (!res.success || !res.results?.length) return;
        const events: any[] = res.results.map(event => ({
          ...event,
          betFormModel: { selectedWallet: '', amount: 0 }
        }));
        this.storeService.setMyData( 'availableUserMatches', [...events], true);
      })

  }

  setMatchChanges(match: Match) {
    // tutto da valutare mai neanche provato!
    if (!this.availableMatches || !match) return;
    let indexToEdit = this.availableMatches.findIndex(el => el.id === match.id);
    if (indexToEdit && this.storeService.getCurrentStore().availableUserMatches![indexToEdit]) {
      this.storeService.getCurrentStore().availableUserMatches![indexToEdit] = {...match}
      this.storeService.setMyData( 'availableUserMatches', {...this.storeService.getCurrentStore().availableUserMatches});
    }
  }

  get availableMatches() {
    return this.storeService.getCurrentStore().availableUserMatches;
  }

  get userWallet() {
    return this.storeService.getCurrentStore().wallet;
  }

  get userSubscription() {
    return this.storeService.getCurrentStore().userSubscription;
  }

  get isLogged() {
    return this.storeService.getCurrentStore().authUser.isLogged;
  }

  userTerm(match: DetailedMatch) {
    if (!this.isLogged) return;
    return match.terms?.find(el => el.planId === this.userSubscription!.id)!;
  }

  betLevel(match: DetailedMatch) {
    let currentBettingAmount = match && match.currentTotalBettingAmount ? +match.currentTotalBettingAmount : 0;
    return currentBettingAmount / +match.maxTotalBettingAmount * 100;
  }

  userSurplus(terms: MatchTerm[]) {
    if (!this.isLogged) return;
    return terms.find(el => el.planId === this.userSubscription!.id);
  }

  checkMaxBet(ev: any, match: DetailedMatch) {
    debugger;
    if (!this.isLogged) return;

    // calculate the sum of the bet of all bettings
    let betForThisMatch = 0;
    this.storeService.getData().value.activeUserBets?.forEach((el: UserBet) => {
      if (el.matchId === match.id) {
        betForThisMatch += +el.betAmount;
      }
    });

    const term = this.userTerm(match)!;
    if (+match.betFormModel!.amount! > (+term.maxBet - +betForThisMatch)) {
      if ((+term.maxBet - +betForThisMatch) > 0) {
        match.betFormModel!.amount = +term.maxBet - +betForThisMatch;
      } else {
        match.betFormModel!.amount = 0
      }
      match.fontendMessage = 'MAXBETLIMIT';
    }

  }

  calculateWinning(match: DetailedMatch): {name: string, amount: number}[] {
    if (!match.betFormModel?.amount || !match.betFormModel?.selectedWallet) {
      return [];
    }
    // get bet details
    const amountBet = match.betFormModel!.amount;
    const userTerm = match.terms?.find(el => el.planId === this.userSubscription!.id)!;
    const normalWin = amountBet * match.quota;
    const amountSurplus = (normalWin / 100) * userTerm.surplusAmount;

    return [{name: 'WIN', amount: normalWin}, {name: 'Surplus', amount: amountSurplus}, {name: 'TOTALWIN', amount: normalWin + amountSurplus}]
  }

  cashierAmount(idx: string) {
    if (idx !== 'TOTAL') {
      return this.userWallet[idx as keyof UserWallet]
    }
    let total = 0;
    for (const idxKey in this.userWallet) {
      total += +this.userWallet[idxKey as keyof UserWallet];
    }

    return total;
  }
  async addBet(match: DetailedMatch) {
    console.log(`Aggiungendo scommessa per l'evento ${match.id}`, match.betFormModel);
    // Qui puoi gestire la logica per aggiungere la scommessa all'evento,
    // come l'invio dei dati al server.
    if (!match.userBets) {
      match.userBets = [];
    }
    if (+match.betFormModel!.amount! > +this.userWallet[match.betFormModel!.selectedWallet! as keyof UserWallet]) {
      alert('Non hai abbastanza fondi');
      return;
    }
    // update the wallet
    this.userWallet[match.betFormModel!.selectedWallet! as keyof UserWallet] -= +match.betFormModel!.amount!

    match.betAwaiting = true;
    // set the bet on server and wait for the confirmation and recepit
    const betConfirmation = await this.apiService.authorizedApiCall('/users/placeTheBet', 'POST', {
      matchId: match.id,
      betAmount: +match.betFormModel!.amount!,
      cashLevelFrom: match.betFormModel!.selectedWallet
    }).then(async (res: any) => {
      const msg = await lastValueFrom(this.translate.get(res.msgVar)).then((r) => r);
      if (res.success) {
        match.betAwaiting = false;
        this.communicationService.showSuccessToast('OK', msg);
        match.betFormModel!.selectedWallet = '';
        match.betFormModel!.amount = null;
        // reupdate the current bets

      } else {
        this.communicationService.showErrorToast('KO', msg);
      }
      match.betAwaiting = false;
    }).catch(() => {
      match.betAwaiting = false;
      this.communicationService.showErrorToast('KO', 'Something happened');
    })



    match.userBets.push({
      userId: this.storeService.getCurrentStore().authUser.userId,
      betAmount: +match.betFormModel!.amount!,
      matchId: match.id!,
      currency: 'pEUR',
      originalBet: JSON.stringify(match),
      cashLevelFrom: match.betFormModel!.selectedWallet
    });
  }

  confirmAction(actionId: string, data: any) {
    debugger;
    const modalElement = document.getElementById(actionId);
    if (modalElement) {
      this.modalInstance = new bootstrap.Modal(modalElement);
      this.modalInstance.show();
    }

    const subscription = this.communicationService.confirmation$.subscribe((result: ConfirmationResponse | null) => {
      if (result !== null && result.actionId === actionId) {
        this.modalInstance.hide();
        subscription.unsubscribe();
        this.communicationService.reset();
        if (result.confirmed) {
          console.log(`Azione confermata: ${actionId}`);
          // Esegui l'azione qui in base all'identificatore dell'azione

          if (actionId === 'confirmBet') {
            this.addBet(data);
          }

          const closeModalButton = document.getElementById(actionId);
          if (closeModalButton) {
            closeModalButton.click();
          }
        } else {
          console.log(`Azione annullata: ${actionId}`);
        }
      }
    });
  }

}

interface NewBet {
  eventId: number;
  selectedWallet: string;
  amount: number;
}
