<form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-12 mb-3">

      <div class="card mt-4 card-bg-fill">

        <div class="card-body p-1 p-md-4">
          <div class="text-center mt-2">
            <h5 class="text-primary">{{'CREATENEWACCOUNT' | translate}}</h5>
            <p class="text-muted">{{'CREATENEWACCOUNTAFTER' | translate}}</p>
          </div>
          <div class="p-2 mt-4">

              <div class="mb-3">
                <label for="useremail" class="form-label">Email <span class="text-danger">*</span></label>
                <input #emailField type="email" formControlName="email" class="form-control " id="useremail" placeholder="{{'ENTERYOUREMAIL' | translate}}" required>
                <div *ngIf="registrationForm.get('email')?.errors?.['emailTaken'] && (registrationForm.get('email')?.touched || registrationForm.get('email')?.dirty)" class="alert alert-danger mt-2">
                  {{'EMAILALREADYTAKEN' | translate}}
                </div>
              </div>

              <div class="mb-3">
                <label for="username" class="form-label">Username <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="username" id="username" placeholder="{{'ENTERUSERNAME' | translate}}" required>
                <div *ngIf="registrationForm.get('username')?.errors?.['usernameTaken'] && (registrationForm.get('username')?.touched || registrationForm.get('username')?.dirty)" class="alert alert-danger mt-2">
                  {{'USERNAMEALREADYTAKEN' | translate}}
                </div>
                <div *ngIf="registrationForm.get('username')?.errors?.['minlength'] && (registrationForm.get('username')?.touched || registrationForm.get('username')?.dirty)" class="alert alert-danger mt-2">
                  {{'MINIMUMCHARS' | translate}} 4
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label" for="password-input">Password</label>
                <div class="position-relative auth-pass-inputgroup">
                  <input type="password" formControlName="password" class="form-control pe-5 password-input" onpaste="return false" placeholder="Enter password" id="password-input" aria-describedby="passwordInput">

                  <div *ngIf="registrationForm.get('password')?.errors && (registrationForm.get('password')?.touched || registrationForm.get('password')?.dirty)"
                       class="p-3 bg-light mb-2 rounded">
                    <h5 class="fs-13">{{'PASSMUSTCONTAIN' | translate}}:</h5>
                    <p id="pass-length" class="invalid fs-12 mb-2">{{'MINIMUMCHARS' | translate}} 8</p>
                  </div>
                </div>
              </div>

            <div class="mb-3">
              <label class="form-label" for="password-input">Password2</label>
              <div class="position-relative auth-pass-inputgroup">
                <input type="password" formControlName="password2" class="form-control pe-5 password-input" onpaste="return false" placeholder="{{'ENTERPASSWORD' | translate}}" id="apassword-input" aria-describedby="passwordInput">
                <div *ngIf="registrationForm.get('password')?.value !== registrationForm.get('password2')?.value && (registrationForm.get('password')?.touched || registrationForm.get('password')?.dirty)"
                     class="p-3 bg-light mb-2 rounded">
                  <h5 class="fs-13">{{'PASSMUSTMATCH' | translate}}</h5>
                </div>
                <div class="invalid-feedback">
                  {{'REPEATPASS' | translate}}
                </div>
              </div>
            </div>

              <div class="mb-4">
                <p class="mb-0 fs-12 text-muted fst-italic">{{'AGREEWITHTERMS' | translate}} <a [routerLink]="'/terms'" class="text-primary text-decoration-underline fst-normal fw-medium">{{'TERMS' | translate}}</a></p>
              </div>

              <div class="mt-4">
                <button [disabled]="registrationForm.invalid" class="btn btn-success w-100" type="submit">{{'SIGNUP' | translate}}</button>
              </div>

          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
  </div>

</form>
