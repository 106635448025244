import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StoreService} from '../services/store.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private storeService: StoreService, private router: Router, private act: ActivatedRoute) {}

  webEnvironment: string = environment.production ? 'production' : 'development';
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem(environment.accessWebToken);
    debugger
    if (token === '') {
      // not token available get it from store
      token = this.storeService.getData().value.authUser.authUserData.token || null;
    }

    const urlsFree = ['home', 'resetPassword', 'validateaction'];
    if ((!token || token ==='') && this.storeService?.getData()?.value?.navigation?.currentUrl && !urlsFree.includes(this.storeService.getData().value.navigation?.currentUrl!)) {
      // this.router.navigateByUrl('/login');
    }
    if (token) {

      const cloned = request.clone({
        headers: request.headers.set("Authorization", token)
          .set('X-Environment', this.webEnvironment)
      });

      return next.handle(cloned);
    } else {

      const cloned = request.clone({
        headers: request.headers.set('X-Environment', this.webEnvironment)
      });
      return next.handle(cloned);
    }
  }

}
