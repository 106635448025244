import {Component, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import {JsonFormData} from '../../../interfaces/jsonFormData';
import {CommunicationService} from '../../services/communication.service';
import {StoreService} from "../../services/store.service";

@Component({
  selector: 'app-validateaction',
  templateUrl: './validateaction.component.html',
  styleUrls: ['./validateaction.component.scss']
})
export class ValidateactionComponent {

  router = inject(Router);
  actionToken: string = '';
  uidForAction: string = '';
  idAction: string = '';
  formData: JsonFormData = {elements: []};
  storeService = inject(StoreService);
  constructor(private route: ActivatedRoute, private apiService: ApiService, private communicationService: CommunicationService) {
    this.route.queryParams
      .subscribe(params => {
          console.log(params); // { orderby: "price" }
          this.actionToken = params['cc'];
          this.getValidateAction(this.actionToken);
        }
      );

    this.formData.align = 'BLOCK';
    this.formData.actionText = 'Modifica Password';
    this.formData.elements = [
      {
        'labelWidthClass': 'label-width-200',
        'text': 'Nuova Password',
        'type': 'password',
        'name': 'password',
        'id': 'password'
      },
      {
        'labelWidthClass': 'label-width-200',
        'text': 'Verfica Password',
        'type': 'password',
        'name': 'verifyPassword',
        'id': 'verifyPassword'
      }
    ]
  }

  action(ev: any) {
    if (ev.password !== ev.verifyPassword) {
      alert('Le password non coincidono');
      return;
    }

    this.apiService.makeApiCall('/users/resetNewPass', 'POST', {newPass: ev.password, token: this.actionToken})
      .subscribe( (res: any) => {
        console.log(res.msg);
        if (res.success) {
          this.communicationService.showSuccessToast('Nuova password settata', 'La nuova password è stata definita.');
        } else {
          this.communicationService.showErrorToast('Errore', res.msg || 'Errore nel settaggio nuova password.');
        }
      });
  }

  getValidateAction(cc: string) {
    this.apiService.makeApiCall('/users/actionConfirmation', 'POST', {cc: cc})
      .subscribe( (res: any) => {
        if (res.message) {
          this.communicationService.showSuccessToast('Message', res.message);
        }
        setTimeout(() => {
          this.parseValidationResult(res);
        }, res.timeout || 1000)

      });
  }

  parseValidationResult(validationData: any) {

      if (validationData.meta?.actionRequired) {
        switch(validationData.meta?.actionRequired) {
          case 'logout':
            this.storeService.doLogout();
            break;
        }
      }
      if (validationData.meta.redirectUrlOK) {
        this.router.navigateByUrl(validationData.meta.redirectUrlOK);
      } else if (validationData.meta.redirectUrlKO) {
        this.router.navigateByUrl(validationData.meta.redirectUrlKO);
      }

  }

}
