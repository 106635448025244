import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {JsonFormData, JsonFormElement} from '../../../interfaces/jsonFormData';
import {provinceItalia, regioniItalia, nazioni, StoreService} from '../../services/store.service';
import {ApiService} from '../../services/api.service';
import {Contract_doc} from "../../../interfaces/production";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {UploaderComponent} from "../uploader/uploader.component";
import {RouterModule} from "@angular/router";

@Component({
  selector: 'app-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
  imports: [
    FormsModule, CommonModule, UploaderComponent, RouterModule
  ],
  standalone: true
})
export class JsonEditorComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() formConfig: JsonFormData | undefined | null;
  @Output() saveForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() innerActionOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadCollection: EventEmitter<any> = new EventEmitter<any>();

  formData: any = {};
  show = false;

  regioneSelezionata: string | null | undefined = null;
  provinciaSelezionata: string | null | undefined = null;
  provinceFiltrate: any[] = [];
  comuniFiltrati: any[] = [];

  constructor(private apiService: ApiService, private storeService: StoreService) {}

  ngOnInit(): void {
  }

  asString(value: any) {
    return value as string;
  }

  ngAfterViewInit() {
    // this.initializeFormData();
    setTimeout(() => {
      this.initializeFormData();
      this.filtraProvince();
    }, 300)
  }


  ngOnChanges() {
    debugger
      this.formData = {}; // se non resetto mantiene i dati precedenti
      this.initializeFormData();
      this.filtraProvince();
      this.filtraComuni();
       console.log('Changes', this.formData, this.formConfig?.elements);
  }

  changed(ev: any = null, el: any = null) {
    debugger
    // console.log('Event', this.formData);
    if (el.mode === 'ONCHANGE') {
      this.innerAction(el);
    }
    if (ev === null) {return;}

    const selectedValue = ev.target.value;
    const selectedIndex = ev.target.selectedIndex;
    const selectedOptionText = ev.target.options[selectedIndex].textContent;
    if (selectedValue === 'undefined') {
      return;
    }
    // avoid double
    const exist = el.innerData.findIndex((el: string) => el.toString() === selectedValue.toString());
    if (exist < 0) {
      el.innerData.push(selectedValue);
    }

    this.formData[el.id] = el.innerData.join(',');

    debugger;
  }

  readOptionValue(el: any, key: string) {
    const elem = el.options.find((el: { name: string; }) => el.name === key);
    return elem.value;
  }

  readInnerData(id: number, el: any) {

    debugger
    const elem = el.options.find((el: { value: number; }) => +el.value === +id);
    if (!elem) {
      return null;}
    return elem[el?.propToRead];
  }

  innerAction(element: any, data: any = null) {
    debugger;

    this.innerActionOut.emit({actionId: element.id, formData: this.formData, element, data});
  }

  removeInnerData(id: string, source: any) {
    debugger;
    const idx = source.findIndex((el: string) => el === id);
    source.splice(idx, 1);
  }

  setSql(toSql: any) {
    return this.storeService.convertHtmlToMysqlDate(toSql);
  }

  initializeFormData() {

    if (this.formConfig && this.formConfig.elements.length) {
      this.formConfig.elements.forEach((element: any) => {
        if (element.type === 'provincia') {
          if (element.innerProp && element.innerProp !== '') {
            if (!this.formData[element.innerProp]) {
              this.formData[element.innerProp] = {};
            }
            this.formData[element.innerProp]['nazione'] = element.value4 || 'Italia';
            this.formData[element.innerProp]['regione'] = element.value;
            this.formData[element.innerProp]['provincia'] = element.value2;
            this.formData[element.innerProp]['comune'] = element.value3;
            this.filtraComuni(element.value2);
          } else {
            this.formData['nazione'] = element.value4 || 'Italia';
            this.formData['regione'] = element.value;
            this.formData['provincia'] = element.value2;
            this.formData['comune'] = element.value3;
            this.filtraComuni(element.value2);
          }

        } else {
          if (element.innerProp && element.innerProp !== '') {
            if (!this.formData[element.innerProp]) {
              this.formData[element.innerProp] = {}
            }
            this.formData[element.innerProp][element.id] = element.value || '';
          } else {
            this.formData[element.id] = element.value || '';
          }

        }

      });
    }
  }

  filtraProvince() {
    if (this.regioneSelezionata) {
      this.provinceFiltrate = this.province.filter(
        (provincia) => provincia.regione === this.regioneSelezionata
      );
    } else {
      this.provinceFiltrate = this.province;
    }
  }

  filtraComuni(prov?: string) {
    if (this.provinciaSelezionata || prov) {
      // this.comuniFiltrati = this.c
      const filtra = this.apiService.makeApiCall('/users/getCitiesFromProvince', 'POST', {province: this.provinciaSelezionata || prov})
        .subscribe({next: (res: any) => {
          // bconsole.log('Response', res);
            this.comuniFiltrati = res.results;
            filtra.unsubscribe();
          }, error: (err) => {
          // console.log('Error', err);
            filtra.unsubscribe();
          }});

    }
  }

  get nazioni(): any[] {
    return nazioni;
  }
  get province(): any[] {
    return provinceItalia;
  }

  get regioni(): any[] {
    return regioniItalia;
  }


  onSave() {
    // console.log('Saving data', this.formData);
    // detect all data and convert it for mysql

    // fin all innerData
    this.formConfig?.elements.forEach((single) => {
      if (single.innerData) {
        this.formData[single.id] = single.innerData.join(',');
      } else {
        // this.formData[single.id] = single.value;
      }
    });
    this.saveForm.emit(this.formData);
  }

  check(s: any, el: any) {
    s.elementId = el.id
    s.interAction = el.interAction;
    this.innerActionOut.emit(s);
  }

  actionOnForm() {
    this.actionForm.emit(this.formData);
  }

  setDate(ev: any, mode: any) {
    debugger;
    mode = ev;
  }

  deleteElement(el: any) {
    delete this.formData![el.id];

    const idx = this.formConfig!.elements.findIndex((elm: any) => elm && elm.id === el.id);
    this.formConfig!.elements.splice(idx, 1);
    // riattivando questa, i ruoli quando si eliminano si annullano, non va bene
    // this.initializeFormData();
    this.innerActionOut.emit(el)
  }

  searchResults: any[] = [];
  chosenResult: string = '';
  search(ev: any, el: JsonFormElement, minLength: number = 3) {

    if (el.interAction.searchAll) {
      if ((minLength > 0 && ev.length > minLength) || minLength <= 0) {
        // ev.element?.interAction?.isEncrypted || ev.isEncrypted
        // {limit, offset, isEncrypted: ev.element?.interAction?.isEncrypted, searchIn: el.interAction.searchIn, searchTerm: searchTerm}
        this.apiService.makeApiCall(el.interAction.source, 'POST', {isEncrypted: el.interAction?.isEncrypted, searchTerm: ev, searchIn: el.interAction.searchIn, limit: 500, offset: 0})
          .subscribe((res: any) => {
            if (res.success) {
              this.searchResults = [];
              res.results.forEach((single: any) => {
                this.searchResults.push({
                  [el.interAction.searchIn]: single[el.interAction.searchIn],
                  [el.interAction.resultValue]: single[el.interAction.resultValue],
                })
              });
            }
          });

      } else {
        this.innerActionOut.emit(null);
      }
    } else {
      if ((minLength > 0 && ev.length > minLength) || minLength <= 0) {
        this.apiService.makeApiCall(el.interAction.source, 'POST', {key: ev, searchIn: el.interAction.searchIn, limit: 500, offset: 0})
          .subscribe((res: any) => {
            if (res.success) {
              this.searchResults = [];
              res.results.forEach((single: any) => {
                this.searchResults.push({
                  [el.interAction.searchIn]: single[el.interAction.searchIn],
                  [el.interAction.resultValue]: single[el.interAction.resultValue],
                })
              });
            }
          });

      } else {
        this.innerActionOut.emit(null);
      }
    }


  }

  docsCollection(ev: Contract_doc[]) {
    this.uploadCollection.emit(ev)
  }
  uploadDone(ev: any) {

  }

  requiredFilled(): boolean {
    let isGood = true;
    this.formConfig?.elements.forEach((single) => {

      if (single.innerData) {
        this.formData[single.id] = single.innerData.join(',');
      }

      if (single.mode === 'required' && this.formData[single.id] === '' || single.isOnError) {
        isGood = false;
      }
    });

    return isGood

  }


}
