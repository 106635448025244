<!-- Modal -->
<div class="modal fade" id="{{idModal}}" [attr.tabindex]="-1" [attr.aria-labelledby]="idModal + 'lLabel'" [attr.aria-hidden]="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="{{idModal}}Label">{{title}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" [attr.aria-label]="'Close'"></button>
      </div>
      <div class="modal-body" [innerHTML]="modalText">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onCancelAction()">Chiudi</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onConfirmAction()">Conferma</button>
      </div>
    </div>
  </div>
</div>
