import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {CommunicationService} from '../../services/communication.service';
import {ToastEvent} from '../../../interfaces/toast';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent {

  @Input() message: string = 'Hi Everyone!!!';

  currentToasts: ToastEvent[] = [];

  title: string = '';
  small: string = '';

  constructor(private communicationService: CommunicationService, private cdr: ChangeDetectorRef) {
    this.subscribeToToasts();
  }

  turnMeOff() {

  }

  subscribeToToasts() {
    this.communicationService.toastEvents.subscribe((toasts) => {
      const currentToast: ToastEvent = {
        type: toasts.type,
        title: toasts.title,
        message: toasts.message,
      };
      this.currentToasts.push(currentToast);
      this.cdr.detectChanges();
    });
  }

  dispose(index: number) {
    this.currentToasts.splice(index, 1);
    this.cdr.detectChanges();
  }


}
