import {Component, inject} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CommonSharedModuleModule} from "../../../../modules/common-shared-module/common-shared-module.module";
import {JsonEditorComponent} from "../../../../components/json-editor/json-editor.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {firstValueFrom, lastValueFrom} from "rxjs";
import {JsonFormData} from "../../../../../interfaces/jsonFormData";
import {ApiService} from "../../../../services/api.service";
import {CommunicationService} from "../../../../services/communication.service";

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [
    CommonSharedModuleModule,
    JsonEditorComponent,
    TranslateModule
  ],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent {

  formData: JsonFormData = {elements: []};
  translate = inject(TranslateService);
  apiService = inject(ApiService);
  communicationService = inject(CommunicationService);

  route = inject(ActivatedRoute);
  router = inject(Router);
  token: string = ''

  constructor() {
    this.route.queryParams
      .subscribe(params => {
          if (params['token']) {
            this.token = params['token'];
            this.setForm();
          }
        }
      );
  }

  async setForm() {
    this.formData.align = 'INLINE';
    this.formData.actionText = await lastValueFrom(this.translate.get('EMAILCHANGE')).then((r) => r);
    this.formData.elements = [

      {
        'labelWidthClass': '',
        'text': await lastValueFrom(this.translate.get('ENTERNEWEMAIL')).then((r) => r),
        'type': 'input',
        'name': 'email',
        'id': 'email',
        'inline': true
      }
    ];
  }

  changemail(ev: any) {
    // send confirmation request to old email
    debugger
    firstValueFrom(this.apiService.makeApiCall('/users/verifyNewEmail', 'POST', {email: ev.email, confirmationCode: this.token})).then((res: any) => {
      if (res.success) {
        this.communicationService.showSuccessToast('Message', res.msg);
      } else {
        this.communicationService.showErrorToast('Message', res.msg);
      }
    });
    // changeOwnEmail
  }

}
