import {Component, ElementRef, ViewChild} from '@angular/core';
import {navigationTree, StoreService} from '../../services/store.service';
import {NavigationItem} from '../../../interfaces/navigation';
import {NgIf, NgFor, NgClass, JsonPipe} from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {WalletComponent} from "../wallet/wallet.component";


@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
    standalone: true,
  imports: [RouterLink, NgIf, NgFor, RouterLinkActive, NgClass, JsonPipe, TranslateModule, WalletComponent]
})
export class TopHeaderComponent {

  @ViewChild('tend') tend: ElementRef | undefined;

  mainRoutes: NavigationItem[] = [];
  showFlags: boolean = false;

  constructor(private storeService: StoreService, private translate: TranslateService) {
    this.parseMainNavigation();
  }


  get title() {
    return this.storeService.getCurrentStore().navigation?.currentNavigation?.title
  }

  navigateMenu() {
    if (this.tend) {
      this.tend.nativeElement.classList.remove('show');
    }
  }

  get userIsLogged() {
    return this.storeService.getData().value.authUser.isLogged;
  }

  get userLang() {
    return this.translate.currentLang;
  }
  parseMainNavigation() {
    navigationTree.forEach((singleRoute) => {
      this.mainRoutes.push(singleRoute);
    })
  }

  getPermission(route: NavigationItem): boolean {
    if (route.mustBeNotLogged && this.userIsLogged) {
      return false;
    }
    if (route.permissionNeeded?.permissionName && route.permissionNeeded?.permissionName !== 'empty') {
      return this.storeService.verifyRequestedPermission(route.permissionNeeded.permissionName, route.permissionNeeded.permissions);
    }
    return true;
  }

  setLang(newLangId: string) {
    this.translate.use(newLangId);
    localStorage.setItem(environment.storageLangId, newLangId);
    this.storeService.setMyData('authUser.langId', newLangId, true);
    this.showFlags = false;
  }
}


