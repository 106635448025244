import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators, ReactiveFormsModule} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {emailNotTakenValidator} from '../../validators/emailNotTaken';
import {usernameNotTakenValidator} from '../../validators/usernameNotTaken';
import {CommonModule} from '@angular/common';
import {CommunicationService} from "../../services/communication.service";
import {StoreService} from "../../services/store.service";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {mustMatchValidator} from "../../validators/mustMatch";


@Component({
  selector: 'app-registra-user',
  templateUrl: './registra-user.component.html',
  styleUrls: ['./registra-user.component.scss'],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    RouterLink
  ],
  standalone: true

})
export class RegistraUserComponent implements OnInit {
  @Input() sponsorCode: string | number | null = null;
  @ViewChild('emailField') emailField!: ElementRef;
  @Output() saveForm: EventEmitter<any> = new EventEmitter<any>();
  registrationForm: FormGroup;

  constructor(private apiService: ApiService, private communicationService: CommunicationService, private storeService: StoreService) {
    this.registrationForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [emailNotTakenValidator(apiService)],
        updateOn: 'blur', // Esegui la validazione asincrona quando il campo email perde il focus
      }),
      username: new FormControl('', {
        validators: [Validators.required, Validators.minLength(4)],
        asyncValidators: [usernameNotTakenValidator(apiService)],
        updateOn: 'blur', // Esegui la validazione asincrona quando il campo email perde il focus
      }),
      password: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)],
        updateOn: 'blur'
      }),
      password2: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)],
      }),
    }, { validators: mustMatchValidator('password', 'password2')});
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.registrationForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
    return invalid;
  }

  ngOnInit(): void {}

  onSubmit() {
    debugger
    if (this.registrationForm.valid) {
      if (this.registrationForm.get('password')?.value === this.registrationForm.get('password2')?.value) {
        const userData = this.registrationForm.value;

        userData.bonusId = this.storeService.getData().value.authUser.userId
        debugger;
        if (this.sponsorCode !== null) {
          userData.sponsorCode = this.sponsorCode;
        }

        delete userData.passeord2; // Rimuovi il campo 'confirmPassword' prima di inviare i dati al server
        userData.langId = this.storeService.getCurrentStore().authUser.langId;

        const register = this.apiService.makeApiCall('/users/register', 'POST', userData)
          .subscribe({
          next: (response: any) => {
            console.log('Registrazione avvenuta con successo:', response);
            this.communicationService.showSuccessToast('Registrazione utente', 'Registrazione avvenuta con successo');
            // Gestisci la risposta dell'API qui, ad esempio mostrando un messaggio di successo
            register.unsubscribe();
            this.registrationForm.reset();
            this.saveForm.emit(true)
          },
          error: (error) => {
            console.error('Si è verificato un errore durante la registrazione:', error);
            this.communicationService.showErrorToast('Si è verificato un errore durante la registrazione', error);
            // Gestisci l'errore qui, ad esempio mostrando un messaggio di errore
            register.unsubscribe();
          }
      });
      } else {
        console.error('Le password non coincidono');
        this.communicationService.showErrorToast('Password non corrette', 'Le 2 password non coincidono');
        // Mostra un messaggio di errore per le password non coincidenti
      }
    } else {
      console.error('Form non valido');
      this.communicationService.showErrorToast('Il form non è valido', 'Controlla i dati immessi e riprova');
      // Mostra un messaggio di errore per il form non valido
    }
  }


}
