import {Component, ElementRef, inject, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {RegistraUserComponent} from '../../components/registra-user/registra-user.component';
import {StoreService} from '../../services/store.service';
import {Route, Router, RouterLink} from '@angular/router';
import {NgClass, NgIf} from '@angular/common';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {lastValueFrom} from "rxjs";
import {CommunicationService} from "../../services/communication.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    RouterLink,
    NgIf,
    RegistraUserComponent,
    NgClass,
    TranslateModule
  ],
  standalone: true

})
export class LoginComponent {

  @ViewChild('email') email!: ElementRef;
  @ViewChild('password') password!: ElementRef;
  @ViewChild('rememberMe') rememberMe!: ElementRef;

  translate = inject(TranslateService);
  communicationService = inject(CommunicationService);

  view = 'LOGIN';
  constructor(private apiService: ApiService, private storeService: StoreService, private route: Router) {

  }

  get isLogged() {
    return this.storeService.getData().value.authUser.isLogged;
  }
  reg() {
    this.apiService.makeApiCall('/users/testme', 'GET', {})?.subscribe((res) => {
      console.log('Make call result', res);
    })
  }

  async doLogin(event: any) {
    event.stopPropagation();
    event.preventDefault();

    if (this.email.nativeElement.value && this.password.nativeElement.value) {
      const result: {success: boolean, msgVar?: string} = await this.storeService.doLogin(this.email.nativeElement.value, this.password.nativeElement.value, this.rememberMe.nativeElement.checked);

      if (result.success) {
        await this.route.navigateByUrl('/auth/dashboard')
      } else {
        // get the translation
        const message = await lastValueFrom(this.translate.get(result.msgVar!)).then((r) => r);
        this.communicationService.showErrorToast('Message', message);
      }

    }

  }

  async doLogout() {
    await this.storeService.doLogout();
  }

  testParse() {
    this.apiService.makeApiCall('/users/testParsing', 'GET', {})?.subscribe((res) => {
      console.log('Make call result', res);
    })
  }

}
