<header class="navbar navbar-expand-md navbar-light bg-white fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/images/gptBetLogo.png" alt="Gpt Bet">
    </a>
    <span class="title">{{title || '' | translate}}</span>
    <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>


    <div class="dropdown ms-1 topbar-head-dropdown">
      <button type="button" (click)="showFlags =! showFlags " class="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary " data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img id="header-lang-img" src="assets/images/flags/{{userLang || 'en'}}.svg" alt="Header Language" height="20" class="">
      </button>
      <div [ngClass]="{'show': showFlags}" class="dropdown-menu dropdown-menu-end" id="cashier">
        <!-- item-->
        <a href="javascript:void(0);" (click)="setLang('en')" class="dropdown-item notify-item language py-2" data-lang="en" title="English">
          <img src="assets/images/flags/us.svg" alt="user-image" class="me-2" height="18">
          <span class="align-middle">English</span>
        </a>

        <!-- item-->
        <a href="javascript:void(0);" (click)="setLang('es')" class="dropdown-item notify-item language" data-lang="sp" title="Spanish">
          <img src="assets/images/flags/spain.svg" alt="user-image" class="me-2" height="18">
          <span class="align-middle">Española</span>
        </a>

        <!-- item-->
        <a href="javascript:void(0);"  (click)="setLang('it')" class="dropdown-item notify-item language" data-lang="it" title="Italian">
          <img src="assets/images/flags/italy.svg" alt="user-image" class="me-2" height="18">
          <span class="align-middle">Italiana</span>
        </a>

        <!-- item-->
        <a href="javascript:void(0);"  (click)="setLang('fr')" class="dropdown-item notify-item language" data-lang="fr" title="French">
          <img src="assets/images/flags/french.svg" alt="user-image" class="me-2" height="18">
          <span class="align-middle">français</span>
        </a>

      </div>
    </div><app-wallet class=""></app-wallet>
    <div class="collapse navbar-collapse ml-auto" #tend id="navbarNav">
      <ul class="navbar-nav ml-3">
        <ng-container *ngFor="let it of mainRoutes">
          <li class="nav-item" [ngClass]="it.topClassName" routerLinkActive="active" *ngIf="!it.isInvisible && getPermission(it)">
            <a class="nav-link" [routerLink]="it.path" (click)="navigateMenu()">
              <i [class]="it.iconName"></i>
              <span class="ml-2">{{it.itemName || it.title}}</span>
            </a>
          </li>
        </ng-container>

      </ul>
    </div>


  </div>
</header>

