<div class="container mt-3 betList" *ngIf="availableMatches?.length">
  <div *ngFor="let match of availableMatches let i = index" class="singleBet">
    <div class="row headSide pb-2">
      <div class="col-5 col-sm-2 col-md-3">
        <span class="label">ID</span>
        {{ match.id }}
      </div>
      <div class="col-7 col-sm-8 col-md-6">
        <div class="progress">
          <div class="progress-bar bg-success" role="progressbar" [style.width]="betLevel(match) + '%'"
               [attr.aria-valuenow]="betLevel(match)"
               aria-valuemin="0" aria-valuemax="100">{{ betLevel(match) + '%' }}
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-2 col-md-3 text-center">
        <span class="label"><span>{{match.currentTotalBettingAmount | currency:' '}}</span>/{{ match.maxTotalBettingAmount | currency:' ' }}</span>
      </div>
      <div class="col-12 col-md-12 text-center">
        <span class="label">{{ 'STARTIME' | translate }}</span>
        {{ match.matchStart | date:'long' }}
      </div>
    </div>
    <div class="row teams pt-2">
      <div class="col-4 text-center">
        <img [src]="match.logo1" [alt]="match.team1">
      </div>
      <div class="col-4 text-center">
        {{ match.title }}
      </div>
      <div class="col-4 text-center">
        <img [src]="match.logo2" [alt]="match.team2"><br>
      </div>
      <div class="col-4 p-2  text-center">
        {{ match.team1 }}
      </div>
      <div class="col-4 p-2  text-center">
        <div class="winRes">
          <span *ngIf="match.freeTerm">{{ match.freeTerm }}</span>
          <span *ngIf="!match.freeTerm">{{ match.winnerResult! | translate }}</span>
        </div>
      </div>
      <div class="col-4 p-2  text-center">
        {{ match.team2 }}
      </div>
    </div>
    <div class="row quota mb-1">
      <div class="col-6 text-center">
        <span class="label">{{ 'QUOTA' | translate }}</span><br>
        {{ match.quota }}
      </div>
      <div class="col-6 text-center">
        <span class="label">{{ 'YOURSURPLUS' | translate }}</span><br>
        <span *ngIf="isLogged">{{ userSurplus(match.terms!)?.surplusAmount }}%</span>
        <span *ngIf="!isLogged">fino al 50%</span>
      </div>
    </div>
    <div *ngIf="false" class="row betSide">
      <ng-container *ngFor="let bet of match.userBets">
        <div class="col-4">
          <select [disabled]="true" class="w-100-px">
            <option value="cash" [selected]="bet.cashLevelFrom === 'cash'">Cash: {{ userWallet.cash }}</option>
            <option value="cashLevel1" *ngIf="userWallet.cashLevel1 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel1'">Lev.2: {{ userWallet.cashLevel1 }}
            </option>
            <option value="cashLevel2" *ngIf="userWallet.cashLevel2 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel2'">Lev.3: {{ userWallet.cashLevel2 }}
            </option>
            <option value="cashLevel3" *ngIf="userWallet.cashLevel3 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel3'">Lev.4: {{ userWallet.cashLevel3 }}
            </option>
            <option value="cashLevel4" *ngIf="userWallet.cashLevel4 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel4'">Lev.5: {{ userWallet.cashLevel4 }}
            </option>
            <option value="cashLevel5" *ngIf="userWallet.cashLevel5 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel5'">Lev.6: {{ userWallet.cashLevel5 }}
            </option>
            <option value="cashLevel6" *ngIf="userWallet.cashLevel6 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel6'">Lev.7: {{ userWallet.cashLevel6 }}
            </option>
            <option value="cashLevel7" *ngIf="userWallet.cashLevel7 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel7'">Lev.8: {{ userWallet.cashLevel7 }}
            </option>
            <option value="cashLevel8" *ngIf="userWallet.cashLevel8 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel8'">Lev.9: {{ userWallet.cashLevel8 }}
            </option>
            <option value="cashLevel9" *ngIf="userWallet.cashLevel9 > 0"
                    [selected]="bet.cashLevelFrom === 'cashLevel9'">Lev.10: {{ userWallet.cashLevel9 }}
            </option>
          </select>
        </div>
        <div class="col-4">
          <input type="number" class="w-100-px" [disabled]="true" [value]="bet.betAmount">
        </div>
        <div class="col-4">
          Se vince
        </div>
      </ng-container>
    </div>
    <form #betForm="ngForm" *ngIf="isLogged">
      <div class="row betSide">
        <div class="col-7 ps-1 pe-1">
          <div class="row">
            <div class="col-8 ps-2 pe-1">
              <ng-container *ngIf="cashierAmount('TOTAL') > 0 else noFunds">
                <span class="label">{{ 'WALLET' | translate }}</span>
                <select [(ngModel)]="match.betFormModel!.selectedWallet" style="font-size: .85rem;"
                        name="selectedWallet{{i}}" class="mt-1 w-100-px form-select" ngModel>
                  <option value="cash">Cash: {{ userWallet.cash }}</option>
                  <option value="cashLevel1" *ngIf="userWallet.cashLevel1 > 0">Lev.2: {{ userWallet.cashLevel1 }}</option>
                  <option value="cashLevel2" *ngIf="userWallet.cashLevel2 > 0">Lev.3: {{ userWallet.cashLevel2 }}</option>
                  <option value="cashLevel3" *ngIf="userWallet.cashLevel3 > 0">Lev.4: {{ userWallet.cashLevel3 }}</option>
                  <option value="cashLevel4" *ngIf="userWallet.cashLevel4 > 0">Lev.5: {{ userWallet.cashLevel4 }}</option>
                  <option value="cashLevel5" *ngIf="userWallet.cashLevel5 > 0">Lev.6: {{ userWallet.cashLevel5 }}</option>
                  <option value="cashLevel6" *ngIf="userWallet.cashLevel6 > 0">Lev.7: {{ userWallet.cashLevel6 }}</option>
                  <option value="cashLevel7" *ngIf="userWallet.cashLevel7 > 0">Lev.8: {{ userWallet.cashLevel7 }}</option>
                  <option value="cashLevel8" *ngIf="userWallet.cashLevel8 > 0">Lev.9: {{ userWallet.cashLevel8 }}</option>
                  <option value="cashLevel9" *ngIf="userWallet.cashLevel9 > 0">Lev.10: {{ userWallet.cashLevel9 }}</option>
                </select>
                <span *ngIf="match.betFormModel!.selectedWallet" class="ps-1">
                {{ cashierAmount(match.betFormModel!.selectedWallet) | currency:'pEur' }}
              </span>
              </ng-container>
              <ng-template #noFunds>
                <button class="btn btn-warning">{{'DEPOSITONLY' | translate}}</button>
              </ng-template>
            </div>
            <div class="col-4 ps-1 pe-1">
              <span class="label">{{ 'BET' | translate }}</span>
              <input type="number" style="font-size: .85rem;" [disabled]="!match.betFormModel!.selectedWallet"
                     (keyup)="checkMaxBet($event, match)"
                     [ngClass]="{'wrong': +match!.betFormModel!.amount! > +userTerm(match)!.maxBet!}"
                     class="mt-1 form-control w-50-px" (focus)="match.betFormModel!.amount = null"
                     [(ngModel)]="match.betFormModel!.amount" name="amount{{i}}" ngModel>
              <span *ngIf="match && match.fontendMessage && match.fontendMessage?.length! > 0" class="text-center"><p class="lh-sm">{{match!.fontendMessage! | translate}}</p></span>
            </div>
          </div>
          <div class="row" *ngIf="match.betFormModel!.amount">
            <div class="col-12 mt-1 text-center pe-1">
              <button (click)="confirmAction('confirmBet', match)"
                      class="btn btn-warning btn-sm align-items-center d-flex justify-content-center">
                {{ 'BETNOW' | translate }} {{ match.betFormModel!.amount | currency:'pEur' }}
                <div class="spinner-border spinner-border-sm ms-2" role="status" *ngIf="match.betAwaiting">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col-5 ps-4 pe-1">
          <span class="label">{{ 'WIN' | translate }}</span>
          <ul class="m-0 ps-0 list-unstyled">
            <li *ngFor="let amount of calculateWinning(match)">
              {{ amount.name | translate }}: <span class="onRight"
                                                   [ngClass]="{'total': amount.name === 'TOTALWIN'}">{{ amount.amount | currency: 'pEur' }}</span>
            </li>
          </ul>
        </div>
      </div>

    </form>
    <div class="row">
      <div class="col-12 p-1 mt-2" style="background-color: #d0e0f3; border-radius: 10px;" *ngIf="match!.betFormModel!.amount">
        <app-calculator [gptBet]="+match!.betFormModel!.amount"
                        [gptQuota]="+match.quota + ((+match.quota / 100) * +userTerm(match)!.surplusAmount!)"></app-calculator>
      </div>
      <div class="col-12 text-center pt-2" *ngIf="!isLogged">
        <a routerLink="/register" class="btn btn-outline-danger">{{ 'REGISTER' | translate }}</a>
      </div>
    </div>

  </div>

</div>


<app-confirm-alert idModal="confirmBet" [modalText]="'CONFIRMBETTING' | translate"
                   [title]="'CONFORM' | translate"></app-confirm-alert>

