import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {ApiService} from '../services/api.service';

export function mustMatchValidator(p: string, p2: string): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.get(p) && control.get(p)!.value !== control.get(p2) && control.get(p2)!.value ? null : { mustMatch: true }
  };
}
