import {NgModule} from '@angular/core';
import {PreloadAllModules, Route, RouterModule, Routes} from '@angular/router';
import {navigationTree} from './services/store.service';
import {importWrapper} from './services/importWrapper';
import {CanDeActivateGuard} from './guards/can-de-activate.guard';
import {CanLoadGuard} from './guards/can-load.guard';
import {ResetPasswordComponent} from './pages/user-profile/pages/reset-password/reset-password.component';
import {ValidateactionComponent} from './pages/validateaction/validateaction.component';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from "./pages/register/register.component";
import {HomeComponent} from "./pages/home/home.component";
import {UserProfileComponent} from "./pages/user-profile/user-profile.component";
import {VerifyEmailComponent} from "./pages/validateaction/pages/verify-email/verify-email.component";


function parseRoutes(routeList: any[], parentPath: string = '', permissions: any = {}): Route[] {
  const result: Route[] = [];

   routeList.forEach(route => {

    const fullPath = parentPath ? `${parentPath}/${route.path}` : route.path;
    const currentRoute: Route = {
      path: fullPath,
    };

     currentRoute.canDeactivate = [CanDeActivateGuard];
     currentRoute.canActivate = [CanLoadGuard];
     currentRoute.canActivateChild = [CanLoadGuard];

    currentRoute.loadComponent = importWrapper(fullPath);

    if (route.permissionNeeded) {
      currentRoute.data = {
        permissionNeeded: route.permissionNeeded
      }
    }

     if (parentPath !== '') {
       if (!currentRoute.data) {
         currentRoute.data = {}
       }
       currentRoute['data']['permissionNeeded'] = permissions;
     }

       result.push(currentRoute);


     if (route.children) {
       const childRoutes = parseRoutes(route.children, fullPath, route.permissionNeeded);
       result.push(...childRoutes);
     }


  });
  return result;
}

const routes: Routes = [
  ...parseRoutes(navigationTree),
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'profile', component: UserProfileComponent},
  {path: 'validateaction', component: ValidateactionComponent},
  {path: 'newEmail', component: VerifyEmailComponent},
  {path: 'resetPassword', component: ResetPasswordComponent},
  { path: 'home', component: HomeComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
