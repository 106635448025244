import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanActivateChildFn, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {StoreService} from '../services/store.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private storeService: StoreService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (route.data['permissionNeeded']) {
      if (route.data['permissionNeeded'].permissionName) {
        return this.storeService.verifyRequestedPermission(route.data['permissionNeeded'].permissionName, route.data['permissionNeeded'].permissions);
      }
    }
    return true;

  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route && route['data'] && route.data['permissionNeeded']) {
      return this.storeService.verifyRequestedPermission(route.data['permissionNeeded'].permissionName, route.data['permissionNeeded'].permissions);
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }





}
