import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  baseRouteCall = environment.domain + environment.mainEndpoint + '/';
  constructor(private http: HttpClient) { }

  uploadFile(userId: number, directory: string, file: File, contractId: string): Observable<any> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('contractId', contractId);

    return this.http.post(this.baseRouteCall + `/users/upload/${userId}` + (directory === '' ? '' : '/' + directory), formData);
  }
}
